export const reportNames = Object.freeze({
  simDecisionAnalyticsValue: "simDecisionAnalytics",
  rawDataVersionOfSimSummary: "rawDataVersionOfSimSummary",
});

export const feildSelectionMessage = Object.freeze({
  selectEmails: "Please Select Emails.",
  selectSimulations: "Please Select Simulations.",
  selectReportType: "Please Select Report Type.",
  selectAccounts: "Please Select Accounts.",
  selectCohort: "Please Select Cohort.",
  accountSingeSelect: "Please Select Account.",
  filter: "Please Select at least one Filter."
});
