import Select from "react-select";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import {
  getMultiSelectOptions,
  handleMultiSelectChange,
  selectAllSimulations,
} from "../../../utils/utils";
import { Transition } from "../../../components/Transition";
import auth from "../../../services/auth";
import appService from "../../../services/app.service";
import { useEffect, useState } from "react";
import CohortService from "../../../services/CohortService";
import Loader from "../../../components/loader";

const AssignSimulations = ({ open, handleClose, selected }) => {
  const [simulations, setSimulations] = useState([]);
  const [cohort, setCohortData] = useState({});
  const [selectedSimulations, setSelectedSimulations] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = auth.getUserDetails();

  useEffect(() => {
    setSelectedSimulations(cohort?.simulations);
  }, [cohort]);

  const loadData = async () => {
    setLoading(true);
    const cohortWithSimualationResponse =
      await CohortService().getCohrotWithAssignedSimulations(selected.value);
    setCohortData(cohortWithSimualationResponse.data);
    const account = await appService.get(user?.accId, "accounts/externalSims");
    setSimulations(account?.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleMultiSimulationChange = (value, action) => {
    const list = handleMultiSelectChange(
      value,
      action,
      simulations,
      selectedSimulations,
      selectAllSimulations
    );
    setSelectedSimulations(list);
  };

  const assignSimulations = async () => {
    setLoading(true);
    const params = {
      id: selected.value,
      simulations: selectedSimulations,
      accountId: user?.accId,
    };
    await CohortService().assignSimulationsToCohort(params);
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: "custom-dialog" }}
    >
      <DialogTitle>Assign Simulations</DialogTitle>
      <DialogContent>
        <form className="form-horizontal" noValidate={true}>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label content-body">
              Cohort Name
            </label>
            <div className="col-sm-9">
              <input
                name="name"
                type="text"
                defaultValue={selected.label}
                className="form-control form-control-success fields-font"
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label content-body">
              Select Simulations
            </label>
            <div className="col-sm-9">
              <Select
                styles={{
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px", // Enables scrolling
                    overflowY: "auto",
                  }),
                }}
                name="reportTypes"
                className="basic-multi-select fields-font"
                isMulti={true}
                options={getMultiSelectOptions(
                  simulations,
                  selectedSimulations,
                  selectAllSimulations
                )}
                onChange={handleMultiSimulationChange}
                value={selectedSimulations}
              />
            </div>
          </div>
          <div className="form-group row mt-4">
            <div className="col-sm-9 offset-sm-3">
              <button
                type="button"
                onClick={assignSimulations}
                className="btn btn-submit float-right buttons"
              >
                Assign Sims
              </button>
              <button
                type="button"
                className="btn btn-cancel float-right buttons"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        <Loader loading={loading} />
      </DialogContent>
    </Dialog>
  );
};

export default AssignSimulations;
