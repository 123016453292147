import AppService from "./app.service";

const DasboardService = () => {
  return Object.freeze({
    getCohortParticipantProgressStatusPercentageByAccount: async (accId) => {
      return await AppService.get(accId, "dashboard/cohortProgressStatus");
    },
    getPrePostSimFeedbackRatingQuestionsAverageByAccount: async (accId) => {
      return await AppService.get(accId, "dashboard/ratingFeedbackAverages");
    },
  });
};

export default DasboardService;
