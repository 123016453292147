import React from 'react';
import logo from '../../../assets/img/new_ss_logo.png';
import '../../../assets/css/stripe.css'
import { DefaultFooter } from "../../container/default-layout/footer";
import { supportEmail } from '../../../constants';
import { altAttribute } from '../../../utils/utils';

class FailedPayment extends React.Component<any, any> {

    closeTab() {
        console.log("Close current tab.");
        window.close();//closes the current browser tab
    }
    render() {
        return (
            <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">

                <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                <link rel="stylesheet" id="google-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173" type="text/css" media="all" />
                <link rel="stylesheet" id="font-awesome-css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173" type="text/css" media="all" />

                <div className="site" id="page">
                    <div id="wrapper-navbar" >
                        <nav className="navbar-expand-md navbar-light main-nav">
                            <div className="container">
                                <a href="https://www.schoolsims.com/" className="navbar-brand custom-logo-link" rel="home">
                                    <img width="250" src={logo} className="img-fluid" alt={altAttribute.logoAlt} />
                                </a>
                                <span className="fa-2x fa-ul" style={{ color: "red", textAlign: "center" }}>Payment Failed !</span>
                            </div>
                        </nav>

                    </div>
                </div>
                <div className="container">
                    <section className="dashboard-counts no-padding-bottom">
                        <div className="container-fluid">
                            <div className="row bg-white">
                                <div className="col-12" style={{ maxWidth: 850, alignItems: "center" }}>
                                    <p className="form-control-label" style={{ fontSize: 20 }}>
                                        Hello,
                                        <br /><br />
                                        Thank you for attempting to pay for your SchoolSims subscription. Unfortunately there was an issue with your payment.
                                        Please check with your credit card company and if you need further details, please email <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
                                        <br /><br />
                                        Sincerely,
                                        <br /><br />
                                        SchoolSims Client Experience Team
                                    </p>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <DefaultFooter />
            </div >
        );
    }
}

export default FailedPayment;