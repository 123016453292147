import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import DefaultHeader from "../../container/default-layout/header";
import DefaultSideBar from "../../container/default-layout/side.bar";
import DefaultFooter from "../../container/default-layout/footer";
import { BaseProp } from "../../../types";
import AppService from "../../../services/app.service";
import auth from "../../../services/auth";
import Loader from "../../../components/loader";
import { number } from "prop-types";
import { connect } from "react-redux";
import { formatDateToString } from "../../../utils/dateUtils";
import ClientAdminDashboard from "./ClientAdminDashboard";
import { ACCOUNT_TYPE } from "../../../utils/constants";

const cardUsers: CSSProperties = {
  width: "25%",
  height: "4px",
};
const cardCats: CSSProperties = {
  width: "70%",
  height: "4px",
};
const cardSims: CSSProperties = {
  width: "40%",
  height: "4px",
};
const cardAcc: CSSProperties = {
  width: "50%",
  height: "4px",
};

class Dashboard extends React.Component<BaseProp, any> {
  state = {
    role: "",
    fullName: "",
    username: "",
    accounts: 0,
    users: 0,
    sims: 0,
    totalSimsPlayedLast365Days: 0,
    totalUnpaids: 0,
    totalSimsPlayedToday: 0,
    todayDate: new Date(),
    loading: false,
    accType: "",
    dataCountOfSimsUsed: { entries: [{ simName: String, count: number }] },
    dataMostActiveClients: { entries: [{ client: String, simsUsed: number }] },
    dataSimUsers: {
      entries: [{ date: String, email: String, simName: String }],
    },
  };

  async componentDidMount() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ loading: true });
    const user = await AppService.get(auth.getUserId(), "users/profile");
    auth.setUserDetails(user);
    this.setState({
      role: user["roleName"],
      fullName: user["firstName"] + " " + user["lastName"],
      username: auth.getUserId(),
      accType: auth.getUserDetails().accType,
    });
    const users = await AppService.getAll("dashboard/userCount");
    var accountId = auth.getUserDetails().accId;
    const sims = await AppService.getAllWithData(
      "dashboard/simsCount",
      accountId
    );
    const countSimsUsedStats = await AppService.submit(
      {
        accId: accountId,
        timeZone: timeZone,
      },
      "reports/dashboard/countOfSimsUsed"
    );
    this.setState({
      users: users.data,
      sims: sims.data,
      dataCountOfSimsUsed: countSimsUsedStats.data,
    });

    if (this.state.role === "Role_Super_Admin") {
      const accounts = await AppService.getAll("dashboard/accountCount");
      const mostActiveClientsStats = await AppService.getAll(
        `reports/dashboard/mostActiveClients?timezone=${timeZone}`
      );
      const totalUnpaids = await AppService.getAll(
        "dashboard/countTotalUnpaidPayments"
      );
      const totalSimsPlayedToday = await AppService.submit(
        {
          todayDate: formatDateToString(this.state.todayDate),
          timeZone: timeZone,
        },
        "dashboard/countOfTotalSimsPlayedToday"
      );
      const totalSimsPlayedLast365Days = await AppService.submit(
        {
          todayDate: formatDateToString(this.state.todayDate),
          timeZone: timeZone,
        },
        "dashboard/countOfTotalSimsPlayed365Days"
      );
      this.setState({
        accounts: accounts.data,
        totalSimsPlayedToday: totalSimsPlayedToday.data,
        dataMostActiveClients: mostActiveClientsStats.data,
        totalUnpaids: totalUnpaids.data,
        totalSimsPlayedLast365Days: totalSimsPlayedLast365Days.data,
      });
    }

    if (this.state.role === "Role_Admin") {
      const simUserStats = await AppService.submit(
        {
          accId: accountId,
          timeZone: timeZone,
        },
        "reports/dashboard/simUser"
      );
      this.setState({ dataSimUsers: simUserStats.data });
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <div>
        <div className="page">
          <DefaultHeader history={this.props.history} />
          <div className="page-content d-flex align-items-stretch fields-font">
            <DefaultSideBar
              fullName={this.state.fullName}
              role={this.state.role}
              username={this.state.username}
              activeDashLink={true}
            />
            <div className="content-inner">
              {/* <!-- Page Header--> */}
              <header className="page-header">
                <div className="container-fluid">
                  <h2 className="heading">Dashboard</h2>
                </div>
              </header>
              {/* <!-- Dashboard Counts Section--> */}
              <section className="dashboard-counts no-padding-bottom">
                <div className="container-fluid">
                  <div className="row bg-white has-shadow">
                    {/* <!-- Item --> */}
                    {this.state.role === "Role_Super_Admin" && (
                      <div className="col-xl-4 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-yellow">
                            <i className="icon-padnote" />
                          </div>
                          <div className="title content-body">
                            <Link to="/accounts">
                              <span>
                                Total
                                <br />
                                Accounts
                              </span>
                            </Link>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={cardCats}
                                aria-valuenow={this.state.accounts}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                className="progress-bar bg-yellow"
                              />
                            </div>
                          </div>
                          <div className="number">
                            <strong>{this.state.accounts}</strong>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- Item --> */}
                    {this.state.role === "Role_Super_Admin" && (
                      <div className="col-xl-4 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-orange">
                            <i className="icon-user" />
                          </div>
                          <div className="title content-body">
                            <Link to="/users">
                              <span>
                                Total
                                <br />
                                Users
                              </span>
                            </Link>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={cardUsers}
                                aria-valuenow={this.state.users}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                className="progress-bar bg-orange"
                              />
                            </div>
                          </div>
                          <div className="number">
                            <strong>{this.state.users}</strong>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- Item --> */}
                    <div className="col-xl-4 col-sm-6">
                      <div className="item d-flex align-items-center">
                        <div className="icon bg-green">
                          <i className="icon-bill" />
                        </div>
                        <div className="title content-body">
                          <Link to="/simulations">
                            <span>
                              Total
                              <br />
                              Simulations
                            </span>
                          </Link>
                          <div className="progress">
                            <div
                              role="progressbar"
                              style={cardSims}
                              aria-valuenow={this.state.sims}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              className="progress-bar bg-green"
                            />
                          </div>
                        </div>
                        <div className="number">
                          <strong>{this.state.sims}</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row bg-white has-shadow">
                    {/* Count total unpaids */}
                    {this.state.role === "Role_Super_Admin" && (
                      <div className="col-xl-4 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-red">
                            <i className="fas fa-file-invoice-dollar" />
                          </div>
                          <div className="title content-body">
                            <Link to="/payments">
                              <span>
                                Total
                                <br />
                                Unpaid
                              </span>
                            </Link>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={cardCats}
                                aria-valuenow={this.state.totalUnpaids}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                className="progress-bar bg-red"
                              />
                            </div>
                          </div>
                          <div className="number">
                            <strong>{this.state.totalUnpaids}</strong>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Total Payments today */}
                    {this.state.role === "Role_Super_Admin" && (
                      <div className="col-xl-4 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-green">
                            <i className="fas fa-money-bill-alt" />
                          </div>
                          <div className="title content-body dashboard-sims-played">
                            <span>
                              Sims
                              <br />
                              Played Today
                            </span>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={cardCats}
                                aria-valuenow={this.state.totalSimsPlayedToday}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                className="progress-bar bg-green"
                              />
                            </div>
                          </div>
                          <div className="number">
                            <strong>{this.state.totalSimsPlayedToday}</strong>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- Item --> */}
                    {this.state.role === "Role_Super_Admin" && (
                      <div className="col-xl-4 col-sm-6">
                        <div className="item d-flex align-items-center">
                          <div className="icon bg-violet">
                            <i className="icon-check" />
                          </div>
                          <div className="title content-body dashboard-sims-played">
                            <span>
                              Total
                              <br />
                              Sims Played
                              <br />
                              Last 365 Days
                            </span>
                            <div className="progress">
                              <div
                                role="progressbar"
                                style={cardAcc}
                                aria-valuenow={
                                  this.state.totalSimsPlayedLast365Days
                                }
                                aria-valuemin={0}
                                aria-valuemax={100}
                                className="progress-bar bg-violet"
                              />
                            </div>
                          </div>
                          <div className="number">
                            <strong>
                              {this.state.totalSimsPlayedLast365Days}
                            </strong>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {(this.state.role === "Role_Super_Admin" ||
                  (this.state.accType !== ACCOUNT_TYPE.HIGHER_ED &&
                    this.state.role === "Role_Admin")) && (
                  <div className="container-fluid  mt-1">
                    <div className="row bg-white has-shadow">
                      <ClientAdminDashboard />
                    </div>
                  </div>
                )}

                <div className="container-fluid  mt-1">
                  <div className="row bg-white has-shadow">
                    <div className="col-6">
                      <div className="card-body">
                        <div
                          className="table-responsive"
                          style={{
                            maxHeight: 350,
                            minHeight: 350,
                            backgroundColor: "#F2F2F2",
                            overflowY: "scroll",
                          }}
                        >
                          <table className="table table-striped stats-table">
                            <thead className="stats-table-header">
                              <tr>
                                <th
                                  colSpan={2}
                                  style={{
                                    fontSize: 12,
                                    background: "#272370",
                                    color: "white",
                                  }}
                                >
                                  Count of Sims Used
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="stats-table-header table-heading-in-dashboard"
                                  style={{ padding: 3 }}
                                >
                                  Sim Name
                                </th>
                                <th
                                  scope="col"
                                  className="stats-table-header table-heading-in-dashboard"
                                  style={{ padding: 3 }}
                                >
                                  Count
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.dataCountOfSimsUsed.entries.map(
                                (entry, index) => {
                                  return (
                                    <tr>
                                      <td
                                        scope="row"
                                        className="dashboard-stats-text table-heading-in-dashboard"
                                        style={{ padding: 4 }}
                                      >
                                        {entry.simName}
                                      </td>
                                      <td
                                        scope="row"
                                        className="dashboard-stats-text table-heading-in-dashboard"
                                        style={{ padding: 4 }}
                                      >
                                        {entry.count}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="card-body">
                        {this.state.role === "Role_Super_Admin" && (
                          <div
                            className="table-responsive"
                            style={{
                              maxHeight: 350,
                              minHeight: 350,
                              backgroundColor: "#F2F2F2",
                              overflowY: "scroll",
                            }}
                          >
                            <table className="table table-striped stats-table">
                              <thead className="stats-table-header">
                                <tr>
                                  <th
                                    colSpan={2}
                                    style={{
                                      fontSize: 12,
                                      background: "#272370",
                                      color: "white",
                                    }}
                                  >
                                    {" "}
                                    Client Links Generated
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="stats-table-header table-heading-in-dashboard"
                                    style={{ padding: 3 }}
                                  >
                                    Client Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="stats-table-header table-heading-in-dashboard"
                                    style={{ padding: 3 }}
                                  >
                                    Sims Used
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataMostActiveClients.entries.map(
                                  (entry, index) => {
                                    return (
                                      <tr>
                                        <td
                                          scope="row"
                                          className="dashboard-stats-text table-heading-in-dashboard"
                                          style={{ padding: 4 }}
                                        >
                                          {entry.client}
                                        </td>
                                        <td
                                          scope="row"
                                          className="dashboard-stats-text table-heading-in-dashboard"
                                          style={{ padding: 4 }}
                                        >
                                          {entry.simsUsed}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}

                        {this.state.role === "Role_Admin" && (
                          <div
                            className="table-responsive"
                            style={{
                              maxHeight: 350,
                              minHeight: 350,
                              backgroundColor: "#F2F2F2",
                              overflowY: "scroll",
                            }}
                          >
                            <table className="table table-striped stats-table">
                              <thead className="stats-table-header table-heading">
                                <tr>
                                  <th
                                    colSpan={3}
                                    style={{
                                      fontSize: 12,
                                      background: "#272370",
                                      color: "white",
                                    }}
                                  >
                                    Sim users
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="stats-table-header table-heading"
                                    style={{ padding: 3 }}
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="stats-table-header table-heading"
                                    style={{ padding: 3 }}
                                  >
                                    Sim Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="stats-table-header table-heading"
                                    style={{ padding: 3 }}
                                  >
                                    User
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.dataSimUsers.entries.map(
                                  (entry, index) => {
                                    return (
                                      <tr>
                                        <td
                                          scope="row"
                                          className="dashboard-stats-text content-body"
                                          style={{ padding: 4 }}
                                        >
                                          {entry.date}
                                        </td>
                                        <td
                                          scope="row"
                                          className="dashboard-stats-text content-body"
                                          style={{ padding: 4 }}
                                        >
                                          {entry.simName}
                                        </td>
                                        <td
                                          scope="row"
                                          className="dashboard-stats-text content-body"
                                          style={{ padding: 4 }}
                                        >
                                          {entry.email}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <DefaultFooter />
              {this.state.loading && (
                <div className="overlay">
                  <Loader loading={this.state.loading} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
