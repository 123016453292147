import '../../../assets/css/overlay.css';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/new_ss_logo.png';
import Auth from "../../../services/auth";
import PublicFooterImages from './public-footer-images';
import { altAttribute } from '../../../utils/utils';

class SimFeedback extends React.Component<any, any> {
    loggedIn;
    state = {
        search: ''
    };

    componentDidMount = () => {
        const { history } = this.props;
        window.addEventListener("popstate", () => {
            history.go(1);
        });
        this.loggedIn = Auth.isLoggedIn();
    }

    handleSearch = (event) => {
        this.setState({ search: event.target.value });
    }

    handleSearchInput = (event) => {
        this.setState({ search: event.target.value });
    }

    render() {
        return (
            <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">

                <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                <link rel="stylesheet" id="google-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173" type="text/css" media="all" />
                <link rel="stylesheet" id="font-awesome-css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173" type="text/css" media="all" />

                <div className="site" id="page">
                    <div id="wrapper-navbar" >
                        <nav className="navbar navbar-expand-md navbar-light main-nav">
                            <div className="container">
                                <a href="https://www.schoolsims.com" className="navbar-brand custom-logo-link" rel="home">
                                    <img width="250" src={logo} className="img-fluid" alt={altAttribute.logoAlt} />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" /> </button>
                                <div className="nav-interface">
                                    <form className="search-form" id="searchform" noValidate={true}>
                                        <label> <span className="screen-reader-text" />
                                            <input type="search" className="search-field" placeholder="Search …" name="search" value={this.state.search} onChange={(event) => this.handleSearchInput(event)} />
                                        </label>
                                        <button type="submit" className="searchsubmit" onClick={this.handleSearch}><i className="fa fa-search" /></button>
                                    </form>
                                    <div id="navbarNavDropdown" className="collapse navbar-collapse">
                                        <ul id="main-menu" className="navbar-nav ml-auto">
                                            <li id="menu-item-51" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-51 nav-item">
                                                {!this.loggedIn && <a title="Home" href="https://www.schoolsims.com/" className="nav-link">Home</a>}
                                                {this.loggedIn && <Link title="Home" to="/dashboard" className="nav-link">Home</Link>}
                                            </li>
                                            <li id="menu-item-62" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-54 current_page_item menu-item-62 nav-item">
                                                <a title="Simulations" href="https://www.schoolsims.com/simulations/" className="nav-link">Simulations</a>
                                            </li>
                                            <li id="menu-item-606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-606 nav-item">
                                                <a title="About" href="https://www.schoolsims.com/about/" className="nav-link">About</a>
                                            </li>
                                            <li id="menu-item-156" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item">
                                                <a title="Blog" href="https://www.schoolsims.com/blog/" className="nav-link">Blog</a>
                                            </li>
                                            <li id="menu-item-60" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item">
                                                <a title="Contact Us" href="https://www.schoolsims.com/contact/" className="nav-link">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className="wrapper" >
                        <section className="simulation-header p-0">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mt-4">
                                            <div className="card-body text-center">
                                                <h3>Thanks for visiting</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="wrapper" id="wrapper-footer">
                        <section className="footer-logos">
                            <PublicFooterImages />
                        </section>
                        <section className="footer-end">
                            <div className="inner">
                                <div className="icons">
                                    <a target="_blank" href="https://twitter.com/edleadershipsim?lang=en" rel="noopener noreferrer" >
                                        <i className="icon fa fa-twitter" />
                                    </a>
                                    <a target="_blank" href="https://www.linkedin.com/company/ed-leadership-sims/?viewAsMember=true" rel="noopener noreferrer" >
                                        <i className="icon fa fa-linkedin" />
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                </div >
            </div >
        );
    }

}

export default SimFeedback;