import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppService from "../../../services/app.service";
import Loader from "../../../components/loader";
import AlertDialogSlide from "../../../components/deleteModal";
import Table from "../../../components/Table";
import Layout from "../../../layout/Layout";
import auth from "../../../services/auth";

let cohortParticipant = "";
const CohortParticipants = (props) => {
  const [cohortParticipants, setCohortParticipants] = useState([]);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cohortId, setCohortId] = useState(0);
  const authenticatedUserDetail = auth.getUserDetails();
  const [columnSchema] = useState([
    {
      dataField: "id",
      text: "ID",
      sort: true,
      classes: "content-body",
      headerStyle: { minWidth: 50 },
      headerClasses: "table_header_color",
      align: "left",
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      classes: "content-body",
      headerClasses: "table_header_color",
      align: "left",
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      classes: "paragraph-in-table content-body",
      headerClasses: "table_header_color",
      align: "left",
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      classes: "paragraph-in-table content-body",
      headerClasses: "table_header_color",
      align: "left",
    },
    {
      dataField: "cohort",
      text: "Cohort",
      sort: true,
      classes: "paragraph-in-table content-body",
      headerClasses: "table_header_color",
      align: "left",
      headerStyle: { minWidth: 150 },
    },
    {
      dataField: "active",
      text: "Status",
      sort: true,
      classes: "paragraph-in-table content-body",
      headerClasses: "table_header_color",
      align: "left",
      headerStyle: { minWidth: 150 },
      formatter: (cell) => (cell ? "Active" : "InActive"),
    },
    {
      dataField: "action",
      text: "Action",
      classes: "action text-center",
      headerStyle: { minWidth: 150 },
      headerClasses: "text-center",
      align: "center",
      formatter: (_, participant) => {
        return (
          <Fragment>
            <Link
              className="btn btn-primary btn-sm"
              to={`/cohorts-participants/edit/${participant.id}`}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit" title="Edit Participant" />
            </Link>
            <button
              className="btn btn-danger btn-sm buttons"
              onClick={() =>
                deleteCohortParticipant(
                  participant.id,
                  participant.firstName + " " + participant.lastName
                )
              }
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-trash" title="Delete Participant" />
            </button>
          </Fragment>
        );
      },
    },
  ]);

  const handleOpenDeleteModal = () => setIsShowingModal(true);
  const handleCloseDeleteModal = () => setIsShowingModal(false);

  const deleteCohortParticipant = async (participantId, name) => {
    setCohortId(participantId);
    cohortParticipant = name;
    handleOpenDeleteModal();
  };

  const handleCohortParticipantDeletion = async () => {
    handleCloseDeleteModal();
    setLoading(true);
    const deleteCohortParticipant = await AppService.delete(
      cohortId,
      "cohortParticipant"
    );
    if (deleteCohortParticipant.status === 200) {
      console.debug("Participant Deleted Successfully");
      setLoading(true);
      loadData();
    } else {
      console.debug("Error deleting the participant");
    }
    setLoading(false);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await AppService.getAllWithData(
        "cohortParticipant/getAll",
        authenticatedUserDetail?.accId
      );
      if (response?.data?.data) {
        const formattedData = response.data.data.map((participant) => ({
          ...participant,
          cohort: participant.cohort?.label,
        }));
        setCohortParticipants(formattedData);
      }
    } catch (error) {
      console.error("Error loading cohort participants:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Layout
        title="Participant Management"
        history={props.history}
        activeParticipantLink={true}
        showBreadCrumb={true}
      >
        <section className="tables">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-close">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        to="/cohorts-participants/add"
                      >
                        <i className="fas fa-plus" title="Add Participant" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-header d-flex align-items-center">
                    <h3 className="sub-heading">Participants</h3>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive data-table-wrapper table-heading">
                      <Table
                        data={cohortParticipants}
                        columns={columnSchema}
                        remote={false}
                        id="value"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      {loading && (
        <div className="overlay">
          <Loader loading={loading} />
        </div>
      )}
      <AlertDialogSlide
        show={isShowingModal}
        handleNo={handleCloseDeleteModal}
        handleYes={handleCohortParticipantDeletion}
        title="Delete Cohort Participant"
      >
        <p className="fields-font">
          Are you sure you want to delete the Participant{" "}
          <span className="span">{cohortParticipant}</span> ?
        </p>
      </AlertDialogSlide>
    </div>
  );
};

export default CohortParticipants;
