import { Link } from "react-router-dom";
import DefaultHeader from "../views/container/default-layout/header";
import DefaultSideBar from "../views/container/default-layout/side.bar";
import DefaultFooter from "../views/container/default-layout/footer";

const Layout = (props) => {
  return (
    <div className="page">
      <DefaultHeader history={props.history} />
      <div className="page-content d-flex align-items-stretch fields-font">
        <DefaultSideBar
          activeAdd={props.activeAdd}
          activeAaqep={props.activeAaqep}
          isStandardOpen={props.isStandardOpen}
          activeAccreditationDashboard={props.activeAccreditationDashboard}
          activeCohortLink={props.activeCohortLink}
          activeParticipantLink={props.activeParticipantLink}
          activeEducatorInsightsDashboard={props.activeEducatorInsightsDashboard}
        />
        <div className="content-inner">
          {/* <!-- Page Header--> */}
          <header className="page-header">
            <div className="container-fluid">
              <h2 className="heading">{props.title}</h2>
            </div>
          </header>
          {/* <!-- Breadcrumb--> */}
          {props.showBreadCrumb && (
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item breadcrumb-list">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active breadcrumb-list">
                  {props.subTitle && (
                    <Link to={props.titleUrl}>{props.title}</Link>
                  )}
                  {!props.subTitle && props.title}
                </li>
                {props.subTitle && (
                  <li className="breadcrumb-item active breadcrumb-list">
                    {props.subTitle}
                  </li>
                )}
              </ul>
            </div>
          )}
          {props.children}
          <DefaultFooter />
        </div>
      </div>
    </div>
  );
};

export default Layout;
