import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Player } from 'video-react';
import '../../../assets/css/overlay.css';
import logo from '../../../assets/img/new_ss_logo.png';
import Loader from '../../../components/loader';
import AppService from "../../../services/app.service";
import { ISimulation } from '../../../types/index';
import PublicFooterImages from './public-footer-images';
import { altAttribute } from '../../../utils/utils';

class ListPublicSimulations extends React.Component<
    ISimulation,
    any
> {

    state = {
        data: [],
        oldList: [],
        search: '',
        id: 0,
        pselStandards: [],
        nelpStandards: [],
        stakeholders: [],
        protagonists: [],
        filterPselStandards: [],
        filterNelpStandards: [],
        filterStakeholders: [],
        filterProtagonists: [],
        loading: false
    };


    loadData = async () => {
        this.setState({ loading: true });
        const sims = await AppService.getAll('sims/public/0');
        const pselStandards = await AppService.getAll('standards/getAll');
        const nelpStandards = await AppService.getAll('nelpStandards/getAll');
        const protagonists = await AppService.getAll('protagonists/getAll');
        const stakeholders = await AppService.getAll('stakeholders/getAll');


        this.setState({
            loading: false,
            data: sims['data'],
            oldList: sims['data'],
            pselStandards: pselStandards['data'],
            nelpStandards: nelpStandards['data'],
            protagonists: protagonists['data'],
            stakeholders: stakeholders['data'],
        });
    }

    loadFilteredSims = async () => {

        const filterCriteria = {
            account: 0,
            stakeholders: this.state.filterStakeholders.length > 0 ? this.state.filterStakeholders : [],
            protagonists: this.state.filterProtagonists.length > 0 ? this.state.filterProtagonists : [],
            nelpStandards: this.state.filterNelpStandards.length > 0 ? this.state.filterNelpStandards : [],
            pselStandards: this.state.filterPselStandards.length > 0 ? this.state.filterPselStandards : []
        }
        this.setState({ loading: true });
        const sims = await AppService.submit(filterCriteria, 'sims/public/filtered');
        this.setState({
            loading: false,
            data: sims['data'],
            oldList: sims['data'],
        });
    }

    componentDidMount = () => {
        this.loadData();
    }

    playVideo = (event) => {
        const element = event.target;
        const blanket = element.closest(".simulation").previousElementSibling;
        blanket.classList.remove("d-none");
        blanket.classList.add("d-block");
        var video = blanket.querySelector('video');
        video.play();
    }

    pauseVideo = (event) => {
        const el = event.target;
        const element = el.closest(".simulation-blanket");
        element.classList.remove("d-block");
        element.classList.add("d-none");
        var video = element.querySelector('video');
        video.pause();
    }

    stopProps = (event) => {
        event.stopPropagation();
    }

    handleChange = (event) => {
        const element = event.target;
        const divs = document.getElementsByClassName("simulation-sidebar-link");
        for (var i = 0; i < divs.length; i++) {
            if (divs[i].classList.contains("red")) {
                divs[i].classList.remove("red");
                break;
            }
        }
        element.parentNode.classList.add("red");
        this.video_filter(element.getAttribute('data-slug'));
    };

    handleSearchInput = (event) => {
        this.setState({ search: event.target.value });
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        let newList = [];
        // Persist old list also
        this.setState({ oldList: this.state.data });
        // If the search bar isn't empty
        if (this.state.search !== "") {
            newList = this.state.data.filter(item => {
                const name = (item['simName'] || '').toLowerCase();
                const description = (item['description'] || '').toLowerCase();
                // change search term to lowercase
                const filter = this.state.search.toLowerCase();
                return name.includes(filter) || description.includes(filter);
            });
            this.setState({
                data: newList,
                loading: false
            });
        } else {
            this.setState({
                data: this.state.oldList,
                loading: false
            });
        }
    }

    video_filter(slug) {
        const divs = document.getElementsByClassName("simulation");
        for (var i = 0; i < divs.length; i++) {
            const element = divs[i];
            var str = element.getAttribute('data-cats') || '';
            if (str.indexOf(slug) !== -1) {
                element.classList.remove("hidden");
            } else {
                element.classList.add("hidden");
            }
        }
    }

    handleStakeChange = selectedOption => {
        this.setState({ filterStakeholders: selectedOption });
    }

    handleProtagonistChange = selectedOption => {
        this.setState({ filterProtagonists: selectedOption });
    }

    handleStandardChange = selectedOption => {
        this.setState({ filterPselStandards: selectedOption });
    }

    handleNelpStandardChange = selectedOption => {
        this.setState({ filterNelpStandards: selectedOption });
    }

    render() {
        const listItems = this.state.data.map((data, index) => {
            const thumbnail = `images/${data['thumbnail']}`;
            const video = `/video/${data['preview']}`;
            let temp = 'All Simulations ';

            return <div key={index}>
                <div className="simulation-blanket" onClick={(event) => this.pauseVideo(event)}>
                    <div className="simulation-video-box">
                        <div className="close-section">CLOSE <i className="fa fa-times"></i></div>
                        <div className="inner">
                            <div className="embed-container">
                                <Player
                                    className='simulation-video'
                                    playsInline
                                    src={video}
                                    onClick={this.stopProps}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="simulation hidden2" data-cats={temp}>
                    <div className="poster sim_2" style={{ backgroundImage: "url(" + thumbnail + ")" }} onClick={(event) => this.playVideo(event)}>
                        <div className="playhead">
                            <div className="icon">
                                <i className="fa fa-play-circle" />
                            </div>
                            <div className="playhead-title">Preview</div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="simulation-title">{data['simName']}</div>
                        <div className="runtime">~{data['simDuration']}</div>
                        <div className="excerpt">
                            <p><span className="sim-detail-description">{data['description']}</span></p>
                        </div>
                        {data['hasAccess'] && <Link className="btn white" to={`simulation/detail/${data['simId']}`}>View Details »</Link>}
                    </div>
                </div>
            </div>
        });

        return (
            <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">

                <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                <link rel="stylesheet" id="google-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173" type="text/css" media="all" />
                <link rel="stylesheet" id="font-awesome-css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173" type="text/css" media="all" />

                <div className="site" id="page">
                    <div id="wrapper-navbar" >
                        <a className="skip-link sr-only sr-only-focusable" href="#content">Skip to content</a>
                        <nav className="navbar navbar-expand-md navbar-light main-nav">
                            <div className="container">
                                <a href="https://www.schoolsims.com" className="navbar-brand custom-logo-link" rel="home">
                                    <img width="480" src={logo} className="img-fluid" alt={altAttribute.logoAlt} />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon" /> </button>
                                <div className="nav-interface">
                                    <form className="search-form" id="searchform" noValidate={true}>
                                        <label> <span className="screen-reader-text" />
                                            <input type="search" className="search-field" placeholder="Search …" name="search" value={this.state.search} onChange={(event) => this.handleSearchInput(event)} />
                                        </label>
                                        <button type="submit" className="searchsubmit" onClick={this.handleSearch}><i className="fa fa-search" /></button>
                                    </form>
                                    <div id="navbarNavDropdown" className="collapse navbar-collapse">
                                        <ul id="main-menu" className="navbar-nav ml-auto">
                                            <li id="menu-item-51" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-51 nav-item">
                                                <a title="Home" href="https://www.schoolsims.com/" className="nav-link">Home</a>
                                            </li>
                                            <li id="menu-item-606" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-606 nav-item" style={{ marginRight: 8 }}>
                                                <a title="About" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link"
                                                    id="menu-item-dropdown-606">About</a>
                                                <ul className="dropdown-menu" aria-labelledby="menu-item-dropdown-606" role="menu">
                                                    <li id="menu-item-794" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-794 nav-item">
                                                        <a title="SchoolSims Overview" href="https://schoolsims.com/overview/" className="dropdown-item">SchoolSims Overview</a>
                                                    </li>
                                                    <li id="menu-item-748" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-748 nav-item">
                                                        <a title="Simulations for K12" href="https://schoolsims.com/k12-sims/" className="dropdown-item">Simulations for K12</a>
                                                    </li>
                                                    <li id="menu-item-749" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-749 nav-item">
                                                        <a title="Simulations for Higher Ed" href="https://schoolsims.com/higher-ed-sims/" className="dropdown-item">Simulations for Higher Ed</a>
                                                    </li>
                                                    <li id="menu-item-1236" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1236 nav-item">
                                                        <a title="Simulations for Teachers" href="https://schoolsims.com/simulations-for-teachers/" className="dropdown-item">Simulations for Teachers</a>
                                                    </li>
                                                    <li id="menu-item-751" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-751 nav-item">
                                                        <a title="Title Funding for PD" href="https://schoolsims.com/title/" className="dropdown-item">Title Funding for PD</a>
                                                    </li>
                                                    <li id="menu-item-1077" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1077 nav-item">
                                                        <a title="Alignment with PSEL Standards" href="https://schoolsims.com/alignment-with-psel-standards/" className="dropdown-item">Alignment with PSEL Standards</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-62" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-54 current_page_item active menu-item-62 nav-item">
                                                <Link to="/public/simulations" title="Simulations" className="nav-link">Simulations</Link>
                                                {/* <a title="Simulations" href="https://www.schoolsims.com/simulations/" className="nav-link">Simulations</a> */}
                                            </li>
                                            <li id="menu-item-63" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-606 nav-item">
                                                <a title="Simulations" href="https://schoolsims.com/try-a-sim/" className="nav-link">Try a Free Sim</a>
                                            </li>
                                            <li id="menu-item-853" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-853 nav-item" style={{ marginRight: 8 }}>
                                                <a title="Resource Library" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-toggle nav-link" id="menu-item-dropdown-853">Resource Library</a>
                                                <ul className="dropdown-menu" aria-labelledby="menu-item-dropdown-853" role="menu">
                                                    <li id="menu-item-156" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-156 nav-item">
                                                        <a title="Blog" href="https://schoolsims.com/blog/" className="dropdown-item">Blog</a>
                                                    </li>
                                                    <li id="menu-item-885" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-885 nav-item">
                                                        <a title="Webinars" href="https://schoolsims.com/webinars/" className="dropdown-item">Webinars</a>
                                                    </li>
                                                    <li id="menu-item-854" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-854 nav-item">
                                                        <a className="dropdown-item">Title Funding for PD</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-60" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item">
                                                <a title="Contact Us" href="https://schoolsims.com/contact/" className="nav-link">Learn More</a>
                                            </li>
                                            <li id="menu-item-65" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-60 nav-item">
                                                <a title="Contact Us" href="https://admin.schoolsims.com/" className="nav-link">Login</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className="wrapper" >
                        <section className="simulation-header p-0">
                            <h3 className="title">Simulation Library</h3>
                        </section>
                        <section className="simulations-section p-0">
                            <div className="simulation-sidebar">
                                <div className="row">
                                    <label className="col-sm-12 form-control-label">StakeHolder</label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select name="stakeHolder" isMulti={true} options={this.state.stakeholders} className="basic-multi-select" value={this.state.filterStakeholders} onChange={this.handleStakeChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label">Protagonist</label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select name="protagonist" isMulti={true} options={this.state.protagonists} className="basic-multi-select" value={this.state.filterProtagonists} onChange={this.handleProtagonistChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label">PSEL Standard</label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select name="standard" isMulti={true} options={this.state.pselStandards} className="basic-multi-select" value={this.state.filterPselStandards} onChange={this.handleStandardChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-12 form-control-label">NELP Standard</label>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Select name="nelpStandard" isMulti={true} options={this.state.nelpStandards} className="basic-multi-select" value={this.state.filterNelpStandards} onChange={this.handleNelpStandardChange} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-12" style={{ textAlign: "right" }}>
                                        <button type='button' className="btn-submit btn-lg" onClick={() => { this.loadFilteredSims() }} >Filter <i className=" fa fa-filter" /></button>
                                    </div>
                                </div>
                            </div>

                            <div className="simulations">
                                {listItems}
                            </div>
                        </section>

                    </div>
                    {(this.state.loading) && <div className="overlay">
                        <Loader loading={this.state.loading} />
                    </div>}
                    <div className="wrapper text-center" id="wrapper-footer">
                        <section className="simulation-header p-0" style={{ background: '#272370' }}>
                            <a style={{ color: '#ffffff', fontSize: 20, textDecoration: 'underline' }} href="https://www.edsimspd.com/simulation-list-flash/" className="title">Please access our remaining Flash simulations here. We are actively working to convert these all to HTML5.</a>
                        </section>
                        <section className="subfooter p-0" style={{ backgroundImage: 'url(https://www.schoolsims.com/wp-content/uploads/2019/12/sub-footer-image.jpg)' }}>
                            <div className="sf-logo" style={{ backgroundImage: 'url(https://www.schoolsims.com/wp-content/uploads/2019/12/logo-big-white-2.png)' }} />
                            <div className="title">Experience is the Best Teacher</div>
                            <a href="https://www.schoolsims.com/contact/">Learn More »</a>
                        </section>
                        <section className="footer-logos">
                            <PublicFooterImages />
                        </section>
                        <section className="footer-end p-0">
                            <div className="inner">
                                <div className="icons">
                                    <a target="_blank" href="https://twitter.com/edleadershipsim?lang=en" rel="noopener noreferrer" >
                                        <i className="icon fa fa-twitter" />
                                    </a>
                                    <a target="_blank" href="https://www.linkedin.com/company/ed-leadership-sims/?viewAsMember=true" rel="noopener noreferrer" >
                                        <i className="icon fa fa-linkedin" />
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div >
        );
    }

}

export default ListPublicSimulations;