import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import DasboardService from "../../../services/DashboardService";
import auth from "../../../services/auth";
import {
  PRE_SIM_RATING_QUESTIONS,
  POST_SIM_RATING_QUESTIONS,
  PIE_CHART_COLORS,
  truncateText,
} from "../../../utils/utils";
import ChartToolTip from "./ChartTooltip";

const chartDivStyle = "col-12 col-md-6 d-flex flex-column align-items-center";
const chartHeading = "sub-heading m-3 text-left";

// Custom Y-Axis Tick Component
const YAxisLabel = ({ x, y, payload }) => {
  const text = payload.value;
  const maxTextLength = 25; // Adjust max length as needed
  const truncatedText = truncateText(text, maxTextLength);

  return (
    <g transform={`translate(${x},${y})`}>
      <title>{text}</title> {/* Tooltip on hover */}
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fontSize={14}
        fill="#000"
        className="bar_chart_ellipsize"
      >
        {truncatedText}
      </text>
    </g>
  );
};

const BarGraph = (showFeedbackCharts, data, chartHeight) => {

  return (
    <>
      {!showFeedbackCharts ? (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
            barCategoryGap="30%"
          >
            <XAxis type="number" domain={[0, 5]} hide />
            <YAxis
              dataKey="name"
              type="category"
              width={200}
              tick={<YAxisLabel />} // Use Custom Tick Component
            />
            <Tooltip content={<ChartToolTip prefix="Average:" />} />
            <Bar
              dataKey="value"
              fill={PIE_CHART_COLORS[0]}
              barSize={Math.max(30, window.innerWidth * 0.05)}
              radius={[5, 5, 5, 5]}
            >
              <LabelList
                dataKey="value"
                position="right"
                fill="black"
                fontSize={14}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className="accreditation-msg">No Data Exists</div>
      )}
    </>
  );
};

const CustomBarChart = () => {
  const [preRatings, setPreRatings] = useState([]);
  const [postRatings, setPostRatings] = useState([]);
  const [showFeedbackCharts, setShowFeedbackCharts] = useState(true);

  const authenticatedUserDetail = auth.getUserDetails();

  const loadData = async () => {
    const ratings =
      await DasboardService().getPrePostSimFeedbackRatingQuestionsAverageByAccount(
        authenticatedUserDetail?.accId
      );

    const transformedPreFeedbackRatings = Object.values(
      ratings?.data?.preSimFeedbackAvg
    ).map((value, index) => ({
      name: PRE_SIM_RATING_QUESTIONS[`preq${index + 2}`],
      value: value,
    }));

    const transformedPostFeedbackRatings = Object.values(
      ratings?.data?.postSimFeedbackAvg
    ).map((value, index) => ({
      name: POST_SIM_RATING_QUESTIONS[`postq${index + 2}`],
      value: value,
    }));

    setShowFeedbackCharts(
      Object.values(ratings?.data?.postSimFeedbackAvg).every((val) => val === 0)
    );

    setPreRatings(transformedPreFeedbackRatings);
    setPostRatings(transformedPostFeedbackRatings);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="d-flex flex-wrap justify-content-center w-100">
      {/* Pre-Sim Feedback Chart */}
      <>
        <div className={chartDivStyle}>
          <h3 className={chartHeading}>{` ${
            authenticatedUserDetail.roleName === "Role_Super_Admin"
              ? "K-12 "
              : ""
          }Learning Culture Pre-Sim Feedback`}</h3>
          {BarGraph(showFeedbackCharts, preRatings, 400)}
        </div>

        {/* Post-Sim Feedback Chart */}
        <div className={chartDivStyle}>
          <h3 className={chartHeading}>{` ${
            authenticatedUserDetail.roleName === "Role_Super_Admin"
              ? "K-12 "
              : ""
          }Learning Culture Post-Sim Feedback`}</h3>
          {BarGraph(showFeedbackCharts, postRatings, 550)}
        </div>
      </>
    </div>
  );
};

export default CustomBarChart;
