import Select from "react-select";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import {
  getMultiSelectOptions,
  handleMultiSelectChange,
  selectAllSimulations,
  ValidateEmail,
} from "../../../utils/utils";
import { Transition } from "../../../components/Transition";
import auth from "../../../services/auth";
import appService from "../../../services/app.service";
import { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Loader from "../../../components/loader";
import CohortService from "../../../services/CohortService";

let linkButtons = "";
const SendSimulationLink = ({
  open,
  handleClose,
  selected,
  loadDataAfterAssignment,
}) => {
  const [linkParams, setLinkParams] = useState({
    accId: 0,
    emailsList: [],
    mailBody: "<h3>Welcome to SchoolSims.</h3>",
    loading: false,
    loadingMessage: "",
    emailErrorMsg: "",
    emailValue: "",
    simErrorMessage: "",
  });

  const [simulations, setSimulations] = useState([]);
  const [selectedSimulations, setSelectedSimulations] = useState([]);
  const user = auth.getUserDetails();

  const loadData = async () => {
    setLinkParams((prevState) => ({
      ...prevState,
      accId: user?.accId,
      loading: true,
    }));

    const cohortWithSimualationResponse =
      await CohortService().getCohrotWithAssignedSimulations(selected.value);
    setSimulations(cohortWithSimualationResponse?.data?.simulations);
    setLinkParams((prevState) => ({ ...prevState, loading: false }));
  };

  useEffect(() => {
    loadData();
    getCohortParticipantsEmail();
  }, []);

  const getCohortParticipantsEmail = async () => {
    setLinkParams((prevState) => ({ ...prevState, loading: true }));
    try {
      const response = await appService.getAllWithData(
        "cohortParticipant/emailsByCohort",
        selected.value
      );
      if (response?.data?.data) {
        setLinkParams((prevState) => ({
          ...prevState,
          emailValue: response?.data?.data,
        }));
        handeEmailsChange(response?.data?.data);
      }
    } catch (error) {
      console.error("Error sending email.", error);
    } finally {
      setLinkParams((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleMultiSimulationChange = (value, action) => {
    setLinkParams((prevState) => ({ ...prevState, simErrorMessage: "" }));
    const list = handleMultiSelectChange(
      value,
      action,
      simulations,
      selectedSimulations,
      selectAllSimulations
    );
    setSelectedSimulations(list);
    generateSimsUrl(list);
  };

  const handleEditorChange = (content, editor) => {
    setLinkParams((prevState) => ({ ...prevState, mailBody: content }));
  };

  const createEmailPreview = () => {
    return { __html: linkParams.mailBody + linkButtons };
  };

  const generateSimsUrl = (list) => {
    var usersSims = [];
    if (list !== null && list[0] !== undefined) {
      list.forEach((sim) => {
        var userSim = { simId: sim["value"], simName: sim["label"] };
        usersSims.push(userSim);
      });
    }
    linkButtons =
      "<br> <h5>Click on the link to view the Simulation.</h5> <br>";
    usersSims.forEach((element) => {
      linkButtons +=
        '<a class="sim-link-btn" href="">' + element.simName + "</a><br>";
    });
    setLinkParams((prevState) => ({
      ...prevState,
      mailBody: linkParams.mailBody,
    }));
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    setLinkParams((prevState) => ({ ...prevState, emailErrorMsg: "" }));
    var emailsData = event.target.value;
    setLinkParams((prevState) => ({ ...prevState, emailValue: emailsData }));
    handeEmailsChange(emailsData);
  };

  const handeEmailsChange = (emailsData) => {
    if (emailsData != null) {
      var mailList = emailsData
        .split(",")
        .join(";")
        .split("\n")
        .join(";")
        .split(";")
        .filter((e) => e !== "");
      if (
        emailsData[emailsData.length - 1] === "," ||
        emailsData[emailsData.length - 1] === ";"
      ) {
        mailList.pop();
      }
      if (mailList.length > 50) {
        setLinkParams((prevState) => ({
          ...prevState,
          hasEmailError: true,
          emailErrorMsg:
            "The maximum email limit is 50. If your email count exceeds this limit, Please select up to 50 emails and send the remaining emails in the next batch.",
        }));
      } else {
        var validEmails = [];
        for (var i = 0; i < mailList.length; i++) {
          var mEamil = mailList[i].trim();
          if (mEamil != null && mEamil !== "" && !ValidateEmail(mEamil)) {
            setLinkParams((prevState) => ({
              ...prevState,
              hasEmailError: true,
              emailErrorMsg:
                "Please enter valid email addresses separated by comma or semicolon.",
            }));
            i = mailList.length; // exit loop on any invalid email address;
          } else if (mEamil !== null && mEamil !== "") {
            validEmails.push(mEamil);
          }
        }
        setLinkParams((prevState) => ({
          ...prevState,
          emailsList: validEmails,
        }));
      }
    }
  };

  const sendLinks = async (e) => {
    if (selectedSimulations.length === 0) {
      setLinkParams((prevState) => ({
        ...prevState,
        simErrorMessage: "Please select simulations.",
      }));
      return;
    }
    if (linkParams.emailsList.length === 0) {
      setLinkParams((prevState) => ({
        ...prevState,
        emailErrorMsg: "Please enter emails.",
      }));
      return;
    }
    e.preventDefault();
    const data = {
      usageType: "Asynchronous",
      simulations: selectedSimulations,
      accId: linkParams.accId,
      emailsList: linkParams.emailsList,
      cohorts: [{ id: selected.value }],
      mailBody: linkParams.mailBody,
    };
    setLinkParams((prevState) => ({
      ...prevState,
      loading: true,
      loadingMessage: "Email sending is in progress !",
    }));
    const sims = await appService.submit(data, "sims/generateUrls");
    if (sims.status === 200) {
      setLinkParams((prevState) => ({
        ...prevState,
        loading: false,
        loadingMessage: "",
      }));
      loadDataAfterAssignment();
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Send Simulation Link</DialogTitle>
      <DialogContent>
        <form className="form-horizontal" noValidate={true}>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label content-body">
              Type
            </label>
            <div className="col-sm-9">
              <input
                name="name"
                type="text"
                defaultValue="Asynchronous SIM"
                className="form-control form-control-success fields-font"
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label content-body">
              Cohort
            </label>
            <div className="col-sm-9">
              <input
                name="name"
                type="text"
                defaultValue={selected.label}
                className="form-control form-control-success fields-font"
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label required content-body">
              Select Simulations
            </label>
            <div className="col-sm-9">
              <Select
                name="simulations"
                className="basic-multi-select fields-font"
                isMulti={true}
                options={getMultiSelectOptions(
                  simulations,
                  selectedSimulations,
                  selectAllSimulations
                )}
                onChange={handleMultiSimulationChange}
                value={selectedSimulations}
              />
              {linkParams.simErrorMessage != "" && (
                <span className="error">{linkParams.simErrorMessage}</span>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label required label">
              Email Addresses
            </label>
            <div className="col-sm-9">
              <textarea
                name="emails"
                rows={5}
                id="emails"
                placeholder="Emails"
                className="form-control form-control-success fields-font"
                onChange={handleEmailChange}
                value={linkParams.emailValue}
              />
              {linkParams.emailErrorMsg != "" && (
                <span className="error">{linkParams.emailErrorMsg}</span>
              )}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-3 form-control-label label">
              Invitation Email Body
            </label>
            <div className="col-sm-9 email-editor fields-font">
              <Editor
                apiKey="oglqjqdz8hzol6kkegtiy5l3yazdflkv0qwti6lae3h1nroy"
                value={linkParams.mailBody}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleEditorChange}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-9 offset-sm-3">
              <button
                type="button"
                onClick={sendLinks}
                className="btn btn-submit float-right buttons"
                disabled={
                  selectedSimulations.length === 0 ||
                  linkParams.emailsList?.length === 0
                }
              >
                Send
              </button>
              <button
                type="button"
                className="btn btn-cancel float-right buttons"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>

            <div className="col-sm-9 offset-sm-3">
              <hr></hr>
              <h4 className="sub-heading">Preview Before You Send!</h4>
              <div
                className="mail-preview-container"
                dangerouslySetInnerHTML={createEmailPreview()}
              />
            </div>
          </div>
        </form>
      </DialogContent>
      {linkParams.loading && (
        <div className="overlay">
          <Loader
            loading={linkParams.loading}
            loadingMessage={linkParams.loadingMessage}
          />
        </div>
      )}
    </Dialog>
  );
};

export default SendSimulationLink;
