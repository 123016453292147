import AppService from "./app.service";

const CohortService = () => {
  return Object.freeze({
    saveCohort: async (cohortData, url) => {
      return await AppService.submit(cohortData, url);
    },

    getCohort: async (cohortId, url) => {
      return await AppService.get(cohortId, url);
    },

    assignSimulationsToCohort: async (cohort) => {
      const assignSimulations = await AppService.submit(
        cohort,
        "cohorts/assignSimulations"
      );
    },

    getCohrotWithAssignedSimulations: async (cohortId) => {
      const cohort = AppService.get(
        cohortId,
        "cohorts/getCohortWithAssignedSimulations"
      );
      return cohort;
    },

    assignAssignmentToCohort: async (cohort) => {
      await AppService.submit(cohort, "cohorts/assignAssignment");
    },

    getCohortParticipantInSightsDashboardData: async (params) => {
      return await AppService.submit(
        params,
        "cohorts/getDashboardDataByCohortId"
      );
    },

    updateCohortParticipantProgressStatus: async (params) => {
      return await AppService.submit(
        params,
        "cohortParticipant/updateProgressStatus"
      );
    },

    generateCohortReport: async (params) => {
      return await AppService.submit(params, "educatorInsight/report");
    },

    getActiveCohorts: async (cohortId) => {
      return await AppService.get(cohortId, "cohorts/getAllActiveForCohorts");
    },
  });
};

export default CohortService;
