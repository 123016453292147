export const ACTIVE = "Active";

export const INACTIVE = "InActive";

export const PRODUCT_TYPE = Object.freeze({
  OTHERS: "Others",
  LEADERSIMS: "Current and Aspiring School Leader Simulations",
  TEACHERSIMS: "Teacher and Teacher Candidate Simulations",
  INDEPENDENT_SCHOOL_ONLY: "Independent School-Specific Simulations",
  SMALL_DISTRICT: "Small District",
  COUNSELORSIMS: "Counselor and Counselor Candidate Simulations",
  STUDENT_FOCUSED: "Student-Focused Simulations",
});

export const ACCOUNT_TYPE = Object.freeze({
  SCHOOL: "School",
  DISTRICT: "District",
  PARTNER: "Partner",
  HIGHER_ED: "Higher Ed",
});
