import { useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../../../components/loader";
import Layout from "../../../layout/Layout";
import auth from "../../../services/auth";
import AlertDialogSlide from "../../../components/deleteModal";
import CohortService from "../../../services/CohortService";

const initialState = {
  name: "",
  description: "",
  active: true,
  accountId: 0,
};

const CreateOrEditCohort = (props) => {
  const [formState, setFormState] = useState({ ...initialState });
  const [, forceUpdate] = useState(0);
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [loading, setLoading] = useState(false);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [isEdit, setIsEdit] = useState(
    !props["match"].params.cohortId
      ? { title: "Add", submitButtonTitle: "Create" }
      : {
          cohortId: props["match"].params.cohortId,
          title: "Edit",
          submitButtonTitle: "Update",
        }
  );

  const loadData = async () => {
    setLoading(isEdit.cohortId ? true : false);
    const authenticatedUserDetail = auth.getUserDetails();
    setFormState((prevState) => ({
      ...prevState,
      accountId: authenticatedUserDetail?.accId,
    }));
    let cohort = {};
    if (isEdit.cohortId) {
      cohort = await CohortService().getCohort(isEdit.cohortId, "cohorts");
      setFormState((prevState) => ({ ...prevState, ...cohort?.data }));
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChange = (event) => {
    const newState = { [event.target.name]: event.target.value };
    setFormState((prevState) => ({ ...prevState, ...newState }));
  };

  const handleCheckBox = (event) => {
    const newState = { [event.target.name]: event.target.checked };
    setFormState((prevState) => ({ ...prevState, ...newState }));
    setIsShowingModal(!event.target.checked ? true : false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      return simpleValidator.current.showMessages();
    }
    setLoading(true);
    const cohort = await CohortService().saveCohort(formState, "cohorts");
    setLoading(false);
    if (cohort.status === 201) {
      console.debug("Cohort created successfully");
      props.history.push("/cohorts");
    } else {
      console.debug("Error while saving the cohort");
    }
  };

  const handleCancel = () => {
    props.history.push("/cohorts");
  };

  const handleNo = () => {
    setFormState((prevState) => ({
      ...prevState,
      active: true,
    }));
    setIsShowingModal(false);
  };

  const handleYes = () => {
    setIsShowingModal(false);
  };

  return (
    <div>
      <Layout
        title="Cohort Management"
        history={props.history}
        titleUrl="/cohorts"
        subTitle={`${isEdit.title} Cohort`}
        activeCohortLink={true}
        showBreadCrumb={true}
      >
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* <!-- Horizontal Form--> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="sub-heading">{isEdit.title} Cohort</h3>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" noValidate={true}>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label required label">
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            name="name"
                            type="text"
                            maxLength={500}
                            placeholder="Cohort Name"
                            className="form-control form-control-success fields-font"
                            value={formState.name}
                            onChange={(event) => handleChange(event)}
                          />
                          {simpleValidator.current.message(
                            "name",
                            formState.name,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label required label">
                          Description
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            name="description"
                            rows={5}
                            maxLength={5000}
                            placeholder="Description"
                            className="form-control form-control-success fields-font"
                            value={formState.description}
                            onChange={(event) => handleChange(event)}
                          />
                          {simpleValidator.current.message(
                            "description",
                            formState.description,
                            "required"
                          )}
                        </div>
                      </div>
                      {isEdit.cohortId && (
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label"></label>
                          <div className="col-sm-9">
                            <div className="custom-control custom-checkbox checkbox-xl">
                              <input
                                id="active"
                                type="checkbox"
                                checked={formState.active}
                                name="active"
                                className="custom-control-input"
                                onChange={(event) => handleCheckBox(event)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <div className="col-sm-9 offset-sm-3">
                          <button
                            type="submit"
                            onClick={submitForm}
                            className="btn btn-submit float-right buttons"
                          >
                            {isEdit.submitButtonTitle}
                          </button>
                          <button
                            type="button"
                            className="btn btn-cancel float-right buttons"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      {loading && (
        <div className="overlay">
          <Loader loading={loading} />
        </div>
      )}
      <AlertDialogSlide
        show={isShowingModal}
        handleNo={handleNo}
        handleYes={handleYes}
        title="Warning!"
        positiveButtonTitle="Yes"
        negativeButtonTitle="No"
      >
        <p>
          Are you sure you want to deactivate all participants, assignments and
          simulation links associated with {" "}
          <span className="span">{formState?.name}</span> ?
        </p>
      </AlertDialogSlide>
    </div>
  );
};

export default CreateOrEditCohort;
