export const SET_LANGUAGE = "SET_LANGUAGE";
export type SET_LANGUAGE = typeof SET_LANGUAGE;

export const SET_COUNTRY = "SET_COUNTRY";
export type SET_COUNTRY = typeof SET_COUNTRY;

export const AUTHENTICATE = "AUTHENTICATE";
export type AUTHENTICATE = typeof AUTHENTICATE;

export const INCREMENT_ENTHUSIASM = "INCREMENT_ENTHUSIASM";
export type INCREMENT_ENTHUSIASM = typeof INCREMENT_ENTHUSIASM;

export const ROLE_CLIENT_ADMIN = "client-admin";
export const ROLE_SUPER_ADMIN = "super-admin";

export const DATE_FORMAT = "MM-DD-YYYY";
export const DATE_TIME_FORMAT = "MM-DD-YYYY HH:mm";

export const BASE_URL_SCHOOLSIMS = "https://schoolsims.com";
export const WEBINARS_URL = "/webinars/";

export const BLOGS_URL = "/blog/";

export const BEST_PRACTISES_URL = "/best-practices/";

export const EVENTS_URL = "/events/";

export const ALL_REPORT_TYPES = [
  {
    value: "countSimsUsed-Admin",
    label: "Count of Sims Used",
    role: [ROLE_CLIENT_ADMIN, ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "simsUser-Admin",
    label: "Sims User",
    role: [ROLE_CLIENT_ADMIN, ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "mostActiveClients-Admin",
    label: "Client Links Generated",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "uniqueSimsByclient-Admin",
    label: "Unique Sims by Clients",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "unpaidStudents-Admin",
    label: "Unpaid Students",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "neverLoggedOnUser-Admin",
    label: "Never Logged on User",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "simsFeedback-Admin",
    label: "Sims Feedback",
    role: [ROLE_SUPER_ADMIN, ROLE_CLIENT_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      selectionTypeExcelOnly: true,
      accountActiveFilter:true
    },
  },
  {
    value: "usersByAccount-Admin",
    label: "Users by Account",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "totalSimsPlayedByClients-Admin",
    label: "Sims Played Count and Average Feedback",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "paidTracnsationsByAccount-Admin",
    label: "Paid Transactions by Account",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "90DaysSimPlayedComparison",
    label: "90 Days Sim Played Comparison by Account",
    role: [ROLE_SUPER_ADMIN, ROLE_CLIENT_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: true,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "90DaysSimPlayedComparisonBySim",
    label: "90 Days Sim Played Comparison by Sim",
    role: [ROLE_SUPER_ADMIN, ROLE_CLIENT_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "AccountFeedbackLast90Days",
    label: "Account Feedback Last 90 Days",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: true,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "FreeTextResponse",
    label: "FreeText Response from SimBuilder Sims",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "SimsPlayedbyHour",
    label: "Sims Played by Hour",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "ClientFacilitatedSimsLast90Days",
    label: "Client Facilitated Sims Last 90 Days",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "studentAccountExpiration",
    label: "Student Account Expiration Report",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: false,
      simSelection: false,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "simDecisionAnalytics",
    label: "Simulation Decision Analytics",
    role: [ROLE_SUPER_ADMIN, ROLE_CLIENT_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: true,
      optionalAccAndSims: "show",
      type: "json",
      sort: true,
      bothSelectionType: true,
      accountActiveFilter:true
    },
  },
  {
    value: "allSimBuilderSimsFeedback",
    label: "All SimBuilder Simulations Feedback Report",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: false,
      simSelection: true,
      optionalAccAndSims: "not show",
      accountActiveFilter:true
    },
  },
  {
    value: "rawDataVersionOfSimSummary",
    label: "Raw Data Version Of Sim Summary Report",
    role: [ROLE_SUPER_ADMIN, ROLE_CLIENT_ADMIN],
    hasDate: {
      dateRangeFilter: true,
      createdDateFilter: false,
      simSelection: true,
      optionalAccAndSims: "show",
      type: "json",
      selectionTypeExcelOnly: true,
      emailSelection: true,
      accountActiveFilter:true,
    },
  },
  {
    value: "cohortProgressReport",
    label: "Cohort Progress Report",
    role: [ROLE_SUPER_ADMIN],
    hasDate: {
      dateRangeFilter: false,
      createdDateFilter: false,
      simSelection: false,
      cohortSelection: true,
      optionalAccAndSims: "not show",
      selectionTypeExcelOnly: true,
      emailSelection: false,
      accountActiveFilter:false,
      participantFilter:true,
    },
  },
];

var ip = window.location.hostname;
var protocol = window.location.protocol === "https:" ? "https" : "http";

export const API_ENDPOINT = `${protocol}://${ip}/api/`;
export const BASE_ENDPOINT = `${protocol}://${ip}/`;
export const PUBLIC_ENDPOINT = `${protocol}://${ip}`;
export const supportEmail = "cx@schoolsims.com";

export const LOCAL_SIM_URL = process.env.REACT_APP_LOCAL_SIM_URL || "";
export const SIM_BUILDER_URL = process.env.REACT_APP_SIM_BUILDER_URL || "";

export const STRIPE_PUBLIC_TOKEN =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || "";

export const Configs = {
  aws: {
    s3: {
      bucketName: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
      s3Url: process.env.REACT_APP_AWS_S3_BUCKET_URL,
    },
  },
};

/**
 * The HELP_PAGE_LINK is used to redirect the help icons to the specified link
 */
export const HELP_PAGE_LINK = "https://schoolsims.com/support/";
