import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Transition } from "../../../components/Transition";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import CohortService from "../../../services/CohortService";
import auth from "../../../services/auth";
import Loader from "../../../components/loader";

const AddAssignment = ({
  open,
  handleClose,
  selected,
  loadDataByCohortIdByPagination,
}) => {
  const [nonSimAssignment, setNonSimAssignment] = useState({
    assignment: "",
    progressNotes: "",
  });
  const [loading, setLoading] = useState(false);

  let user = auth.getUserDetails();
  const accId = user.accId;

  const [, forceUpdate] = useState(0);
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const handleInputChange = (e) => {
    const newState = { [e.target.name]: e.target.value };
    setNonSimAssignment((prevState) => ({ ...prevState, ...newState }));
  };

  const saveAssignment = async (e) => {
    setLoading(true);
    if (simpleValidator.current.allValid()) {
      const params = {
        cohortId: selected.value,
        accountId: accId,
        assignment: nonSimAssignment.assignment,
        progressNotes: nonSimAssignment.progressNotes,
      };
      await CohortService().assignAssignmentToCohort(params);
      loadDataByCohortIdByPagination();
      handleClose();
    } else {
      simpleValidator.current.showMessages();
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Add Assignment</DialogTitle>
      <DialogContent>
        <form className="form-horizontal" noValidate={true}>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label content-body">
              Cohort Name
            </label>
            <div className="col-sm-9">
              <input
                name="name"
                type="text"
                defaultValue={selected.label}
                className="form-control form-control-success fields-font"
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label required label">
              Assignments
            </label>
            <div className="col-sm-9 fields-font">
              <textarea
                name="assignment"
                rows={5}
                maxLength={1000}
                placeholder="Assignments"
                className="form-control form-control-success fields-font"
                value={nonSimAssignment.assignment}
                onChange={handleInputChange}
              />
              {simpleValidator.current.message(
                "Assignment",
                nonSimAssignment.assignment,
                "required"
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label label">
              Progress Notes
            </label>
            <div className="col-sm-9">
              <input
                name="progressNotes"
                type="text"
                maxLength={500}
                placeholder="Progress Notes"
                className="form-control form-control-success fields-font"
                value={nonSimAssignment.progressNotes}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-9 offset-sm-3">
              <button
                type="button"
                onClick={saveAssignment}
                className="btn btn-submit float-right buttons"
              >
                Add Assignment
              </button>
              <button
                type="button"
                className="btn btn-cancel float-right buttons"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        {loading && <Loader loading={loading} />}
      </DialogContent>
    </Dialog>
  );
};

export default AddAssignment;
