import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import '../../../assets/css/stripe.css';
import logo from '../../../assets/img/new_ss_logo.png';
import Loader from "../../../components/loader";
import { STRIPE_PUBLIC_TOKEN } from "../../../constants";
import AppService from "../../../services/app.service";
import { altAttribute, getDecimalFormat, twoDecimalPrecision } from "../../../utils/utils";
import InvalidLink from "./invalidLink";

class CheckoutForm extends React.Component<any, any> {

    state = {
        loading: false,
        hasError: false,
        errorMsg: '',
    }

    handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        const { stripe, elements } = this.props;
        this.setState({ loading: true })

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
            this.setState({ hasError: true, errorMsg: error.message, loading: false })
        } else {
            let stripeToken = paymentMethod.id;
            let uniqueToken = this.props.props['match'].params.token;
            const { source } = await stripe.createSource(cardElement, {
                type: 'card',
                currency: 'usd',
            });
            const stdStripeToken = {
                "uniqueToken": uniqueToken,
                "stripeToken": stripeToken,
                "sourceId": source.id
            }
            const payment = await AppService.submit(stdStripeToken, 'payments/charge');
            if (payment.status === 200) {
                this.props.props.history.push('/payments/successful');
            } else {
                this.props.props.history.push('/payments/failed');
            }
        }
    };

    render() {
        const { stripe } = this.props;
        return (
            <form className="stripPaymentForm" onSubmit={this.handleSubmit}>
                <CardElement
                    onChange={() => { this.setState({ errorMsg: '' }) }}
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />

                {(this.state.hasError) && <div className="srv-validation-message">{this.state.errorMsg}</div>}
                <div style={{ textAlign: 'center' }}>
                    <button type="submit" className="btn-submit" disabled={!stripe}>Pay Now</button>
                </div>
                {(this.state.loading) && <div className="overlay">
                    <Loader loading={this.state.loading} />
                </div>}
            </form>

        );
    }
}

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLIC_TOKEN);

class PaymentForm extends React.Component<any, any>{

    state = {
        amount: '',
        token: '',
        stdEmail: '',
        stdName: '',
        loading: true,
        className: '',
        isTokenValid: true,
    };
    message = '';
    validUser = false;

    loadData = async () => {
        const token = this.props['match'].params.token;
        if (token != null) {
            this.setState({ loading: true });
            const payment = await AppService.getAllWithData('payments/verifyStudentToken', token);
            console.log(payment);
            if (payment.status === 200) {
                if (payment.data.paymentStatus === "Paid") {
                    this.props.history.push('/payments/successful');
                } else if (payment.data.payId) {
                    this.state.amount = getDecimalFormat(payment.data.amount, twoDecimalPrecision);
                    this.state.stdEmail = payment.data.stdEmail;
                    this.state.stdName = payment.data.stdName;
                    this.state.className = payment.data.stdClassName;
                    this.setState({ state: payment.data });
                    this.setState({ isTokenValid: true });
                } else if (payment.data === false) {
                    this.setState({ isTokenValid: false });
                }
            }

            this.setState({ loading: false });
        }
    }

    componentDidMount = () => {
        this.loadData();
    }

    renderPaymentForm() {
        return (
            <div className="page-template page-template-list page-template-list-php page-id-54 wp-custom-logo wp-embed-responsive group-blog">
                <link media="all" href="/wordpress_design.css" rel="stylesheet" />
                <link rel="stylesheet" id="google-fonts-css" href="https://fonts.googleapis.com/css?family=Roboto%3A300%2C400%7CPlayfair+Display&amp;display=swap&amp;ver=0.5.3.1577824173" type="text/css" media="all" />
                <link rel="stylesheet" id="font-awesome-css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=0.5.3.1577824173" type="text/css" media="all" />
                <div className="site" id="page">
                    <div id="wrapper-navbar" >
                        <nav className="navbar-expand-md navbar-light main-nav">
                            <div className="container">
                                <a href="https://www.schoolsims.com/" className="navbar-brand custom-logo-link" rel="home">
                                    <img width="250" src={logo} className="img-fluid" alt={altAttribute.logoAlt} />
                                </a>
                                <span className="fa-2x fa-ul">Online Payment Form</span>
                            </div>
                        </nav>

                    </div>
                </div>
                <div className="padding-top20 container-stripe card">
                    <div className="container">
                        <div>
                            <label className="col-form-label"><span className="fa-2x">Please Enter Your Card Details in the field below:</span>
                            </label>
                        </div>
                        <div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Student Name</label>
                                <div className="col-sm-9">
                                    <input disabled={true} name="label" type="text" className="form-control form-control-success" value={this.state.stdName} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Student Email</label>
                                <div className="col-sm-9">
                                    <input disabled={true} name="label" type="text" className="form-control form-control-success" value={this.state.stdEmail} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Class Name</label>
                                <div className="col-sm-9">
                                    <input disabled={true} name="label" type="text" className="form-control form-control-success" value={this.state.className} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-group row">
                                <label className="col-sm-3 form-control-label">Amount</label>
                                <div className="col-sm-9">
                                    <input disabled={true} name="label" type="text" className="form-control form-control-success" value={this.state.amount} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 form-control-label"></label>
                            <div className="col-sm-9">
                                <Elements stripe={stripePromise}>
                                    <ElementsConsumer>
                                        {({ elements, stripe }) => (
                                            <CheckoutForm props={this.props} elements={elements} stripe={stripe} />
                                        )}
                                    </ElementsConsumer>
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (this.state.loading) && <div className="overlay">
                        <Loader loading={this.state.loading} />
                    </div>
                }
            </div >
        );
    }

    render() {
        return (
            <div>
                {(this.state.isTokenValid && !this.state.loading) && this.renderPaymentForm()}
                {!this.state.isTokenValid && (<InvalidLink />)}
                <Loader loading={this.state.loading} />
            </div>
        )
    }
};

export default PaymentForm;