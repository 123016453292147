import axios from 'axios';
import { BASE_ENDPOINT } from "../constants";
import { logout, updateUserDetail, updateUserProfile } from '../actions';
import store from '../store';
import { loadState } from './localStorage';
var jwtDecode = require('jwt-decode');

let currentUser;

class Auth {
    authenticated;

    constructor() {
        store.subscribe(this.authReduxStoreListener);
        this.authenticated = currentUser?.token ? true : false;

        const state = loadState();
        currentUser = state?.user;
    }

    authReduxStoreListener() {
        currentUser = store.getState()?.user;
    }

    async login(email, password, cb) {
        try {
            const credentials = { email: email, password: password };
            const res = await axios.post(BASE_ENDPOINT + "authenticate", credentials);
            if (res.status === 200) {
                var decoded = jwtDecode(res.data['token']);
                this.authenticated = true;
                const userDetail = { role: decoded.roleName, userId: decoded.userId, token: res.data['token'], profile: {}, accType: decoded.accType };
                store.dispatch(updateUserDetail(userDetail));
                cb();
            } else {
                this.authenticated = false;
                cb('The email / password is incorrect');
            }
        } catch (error) {
            console.error("Err :", error);
            this.authenticated = false;
            cb('The email / password is incorrect');
        }
    }

    async public(method, type, body?, id?) {
        let data, url;
        if (method === 'post') {
            data = body;
            url = `${type}`;
        } else if (method === 'put') {
            data = body;
            url = `${type}/${id}`;
        } else if (method === 'delete') {
            url = `${type}/${id}`;
        } else if (method === 'get') {
            url = id ? `${type}/${id}` : `${type}`;
        }
        const options = {
            method: method,
            data: data,
            url: BASE_ENDPOINT + `${url}`,
        };
        const response = await axios(options);
        return response;
    }

    logout(cb) {
        this.authenticated = false;
        currentUser = null;
        store.dispatch(logout());
        try {
            localStorage.clear();
        } catch (Err) { }
        cb();
    }

    isAuthenticated() {
        return this.getToken();
    }

    public getToken(): string {
        let token = store.getState().user?.token;
        return token;
    }

    public getUserDetails() {
        const userDetail = currentUser?.userDetail;
        return userDetail;
    }

    public setUserDetails(user) {
        const accId = user['accountId'] !== null ? user['accountId'] : 0;
        const profileDetail = { firstName: user['firstName'], lastName: user['lastName'], roleName: user['roleName'], accId, avatar:user['avatar'], accType: user["accType"] };
        store.dispatch(updateUserProfile(profileDetail));
    }

    public isLoggedIn(): boolean {
        return this.getToken() ? true : false;
    }

    public getUserRole() {
        const role = currentUser?.role;
        return role;
    }

    public getUserId() {
        const userId = currentUser?.userId;
        return userId;
    }

    public getAll(_url) {
        return this.public('get', _url);
    }

    public async get(id, _url) {
        const response = await this.public('get', _url, null, id);
        return this.mapResponse(response.data);
    }

    public submit(_url, data) {
        return this.public('post', _url, data);
    }

    private mapResponse(object) {
        var newMap = {};
        for (var i in object)
            newMap[i] = object[i];
        return newMap;
    }

}

export default new Auth();