import React from "react";
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from "../services/protected.routes";
import Standard from "../views/pages/standard/standard";
import AddStandard from "../views/pages/standard/add.standard";
import EditStandard from "../views/pages/standard/edit.standard";
import Stakeholder from "../views/pages/stakeholder/stakeholder";
import AddStakeholder from "../views/pages/stakeholder/add.stakeholder";
import EditStakeholder from "../views/pages/stakeholder/edit.stakeholder";
import Protagonist from "../views/pages/protagonist/protagonist";
import AddProtagonist from "../views/pages/protagonist/add.protagonist";
import EditProtagonist from "../views/pages/protagonist/edit.protagonist";
import UserProfile from "../views/pages/profile/profile";
import UserPassword from "../views/pages/password/password";
import Account from "../views/pages/account/account";
import AddAccount from "../views/pages/account/add.account";
import EditAccount from "../views/pages/account/edit.account";
import AssignSimulation from "../views/pages/account/assign.simulation";
import Simulation from "../views/pages/simulation/admin.simulation";
import AddSimulation from "../views/pages/simulation/add.simulation";
import EditSimulation from "../views/pages/simulation/edit.simulation";
import AssignAccount from "../views/pages/simulation/assign.account";
import SendLink from "../views/pages/simulation/send.link";
import SimulationLinks from "../views/pages/simulation/list.simulation.links";
import ListSimulation from "../views/pages/simulation/list.simulation";
import DetailSimulation from "../views/pages/simulation/detail.simulation";
import Login from "../views/pages/login/Login";
import Forgot from "../views/pages/forgot/Forgot";
import Reset from "../views/pages/reset/reset";
import Dashboard from "../views/pages/dashboard/Dashboard";
import Report from "../views/pages/report/report";
import EditUser from "../views/pages/user/edit.user";
import User from "../views/pages/user/user";
import AddUser from "../views/pages/user/add.user";
import NelpStandard from "../views/pages/nelp-standard/nelp.standard";
import EditNelpStandard from "../views/pages/nelp-standard/edit.nelp.standard";
import AddNelpStandard from "../views/pages/nelp-standard/add.nelp.standard";
import IntascStandard from "../views/pages/intasc-standard/intasc.standard";
import AddIntascStandard from "../views/pages/intasc-standard/add.intasc.standard";
import EditIntascStandard from "../views/pages/intasc-standard/edit.intasc.standard";
import CaepStandard from "../views/pages/caep-standard/caep.standard";
import EditCaepStandard from "../views/pages/caep-standard/edit.caep.standard";
import AddCaepStandard from "../views/pages/caep-standard/add.caep.standard";
import ListPublicSimulations from "../views/pages/public-pages/list.public.simulations";
import DetailPublicSimulation from "../views/pages/public-pages/detail.public.simulation";
import SimFeedback from "../views/pages/public-pages/sim.feedback";
import Classes from "../views/pages/classes/classes";
import AddClass from "../views/pages/classes/add.class";
import EditClass from "../views/pages/classes/edit.class";
import Students from "../views/pages/student/students";
import AddStudent from "../views/pages/student/add.student";
import EditStudent from "../views/pages/student/edit.student";
import Payments from "../views/pages/payment/payments";
import AddPayments from "../views/pages/payment/add.payments";
import PaymentForm from "../views/pages/payment/submit.payment";
import SuccessfulPayment from "../views/pages/payment/successful.payment";
import FailedPayment from "../views/pages/payment/failed.payment";
import EmailTemplate from "../views/pages/email-template/email-template";
import AddEmailTemplate from "../views/pages/email-template/add.email-template";
import EditEmailTemplate from "../views/pages/email-template/edit.email-template";
import ViewSimulation from "../views/pages/simulation/view.simulation";
import Visiting from "../views/pages/public-pages/sim.close";
import AddSimulationDocuments from "../views/pages/simulation-documents/add-simulation-document";
import EditSimulationDocument from "../views/pages/simulation-documents/edit-simulation-documents";
import SimulationDocuments from "../views/pages/simulation-documents/simulation-documents";
import DocumentNotAvailable from "../views/pages/simulation-documents/document-not-available";
import KeyTopics from "../views/pages/key-topics/key-topics";
import AddKeyTopics from "../views/pages/key-topics/add.key-topics";
import EditKeyTopic from "../views/pages/key-topics/edit.key-topics";
import UpdateAds from "../views/pages/ads/updateAds";
import Ads from "../views/pages/ads/ads";
import AAQEPStandard from "../views/pages/aaqep-standard/aaqepStandards";
import CreateOrEditAAQEPStandard from "../views/pages/aaqep-standard/createOrEditAAQEPStandard";
import AccreditationDashboard from "../views/pages/accreditation-dashboard/accreditation-dashboard";
import Cohort from "../views/pages/client_cohorts/cohorts";
import CreateOrEditCohort from "../views/pages/client_cohorts/createOrEditCohorts";
import CohortParticipants from "../views/pages/cohort_participant/cohort_participants";
import CreateOrEditCohortParticipant from "../views/pages/cohort_participant/createOrEditCohortParticipant";
import EducatorInsightsDashboard from "../views/pages/educataor-insights-dashboard/EducatorInsightsDashboard";

export default function Routes(props: any) {
  return (
    <Switch>
      <ProtectedRoute path="/sims" component={ListSimulation} />
      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <ProtectedRoute path="/users" exact={true} component={User} />
      <ProtectedRoute path="/users/add" component={AddUser} />
      <ProtectedRoute path="/users/edit/:userId" component={EditUser} />
      <ProtectedRoute path="/accounts" exact={true} component={Account} />
      <ProtectedRoute path="/accounts/add" component={AddAccount} />
      <ProtectedRoute path="/accounts/edit/:accId" component={EditAccount} />
      <ProtectedRoute
        path="/accounts/simulations/:accId"
        component={AssignSimulation}
      />
      <ProtectedRoute
        path="/users/profile/:userId"
        exact={true}
        component={UserProfile}
      />
      <ProtectedRoute
        path="/users/profile/password/:userId"
        component={UserPassword}
      />
      <ProtectedRoute path="/simulations" exact={true} component={Simulation} />
      <ProtectedRoute path="/simulations/add" component={AddSimulation} />
      <ProtectedRoute
        path="/simulations/edit/:simId"
        component={EditSimulation}
      />
      <ProtectedRoute
        path="/simulations/accounts/:simId"
        component={AssignAccount}
      />
      <ProtectedRoute
        path="/simulations/link/:simId/:accId"
        component={SendLink}
      />
      <ProtectedRoute path="/simulations/links/" component={SimulationLinks} />
      <ProtectedRoute
        path="/nelpstandards"
        exact={true}
        component={NelpStandard}
      />
      <ProtectedRoute path="/nelpstandards/add" component={AddNelpStandard} />
      <ProtectedRoute
        path="/nelpstandards/edit/:stanId"
        component={EditNelpStandard}
      />
      <ProtectedRoute
        path="/intascstandards"
        exact={true}
        component={IntascStandard}
      />
      <ProtectedRoute
        path="/intascstandards/add"
        component={AddIntascStandard}
      />
      <ProtectedRoute
        path="/intascstandards/edit/:stanId"
        component={EditIntascStandard}
      />
      <ProtectedRoute
        path="/caepstandards"
        exact={true}
        component={CaepStandard}
      />
      <ProtectedRoute path="/caepstandards/add" component={AddCaepStandard} />
      <ProtectedRoute
        path="/caepstandards/edit/:stanId"
        component={EditCaepStandard}
      />
      <ProtectedRoute path="/standards" exact={true} component={Standard} />
      <ProtectedRoute path="/standards/add" component={AddStandard} />
      <ProtectedRoute path="/standards/edit/:stanId" component={EditStandard} />
      <ProtectedRoute
        path="/stakeholders"
        exact={true}
        component={Stakeholder}
      />
      <ProtectedRoute path="/stakeholders/add" component={AddStakeholder} />
      <ProtectedRoute
        path="/stakeholders/edit/:stakeId"
        component={EditStakeholder}
      />
      <ProtectedRoute
        path="/protagonists"
        exact={true}
        component={Protagonist}
      />
      <ProtectedRoute path="/protagonists/add" component={AddProtagonist} />
      <ProtectedRoute
        path="/protagonists/edit/:proId"
        component={EditProtagonist}
      />
      <ProtectedRoute path="/reports" exact={true} component={Report} />
      <ProtectedRoute path="/classes" exact={true} component={Classes} />
      <ProtectedRoute path="/classes/add" component={AddClass} />
      <ProtectedRoute path="/classes/edit/:classId" component={EditClass} />
      <ProtectedRoute path="/students" exact={true} component={Students} />
      <ProtectedRoute path="/students/add" component={AddStudent} />
      <ProtectedRoute path="/students/edit/:stdId" component={EditStudent} />
      <ProtectedRoute path="/payments" exact={true} component={Payments} />
      <ProtectedRoute
        path="/payments/add"
        exact={true}
        component={AddPayments}
      />
      <ProtectedRoute
        path="/email-template"
        exact={true}
        component={EmailTemplate}
      />
      <ProtectedRoute
        path="/email-template/add"
        exact={true}
        component={AddEmailTemplate}
      />
      <ProtectedRoute
        path="/email-template/edit/:id"
        exact={true}
        component={EditEmailTemplate}
      />
      <ProtectedRoute
        path="/simulation-document"
        exact={true}
        component={SimulationDocuments}
      />
      <ProtectedRoute
        path="/simulation-document/add"
        exact={true}
        component={AddSimulationDocuments}
      />
      <ProtectedRoute
        path="/simulation-document/edit/:docId"
        exact={true}
        component={EditSimulationDocument}
      />
      <ProtectedRoute path="/key-topics" exact={true} component={KeyTopics} />
      <ProtectedRoute path="/key-topics/add" component={AddKeyTopics} />
      <ProtectedRoute
        path="/key-topics/edit/:keyTopicId"
        component={EditKeyTopic}
      />
      <ProtectedRoute path="/ads" exact={true} component={Ads} />
      <ProtectedRoute path="/ads/add" component={UpdateAds} />
      <ProtectedRoute path="/ads/edit/:ad" component={UpdateAds} />
      <ProtectedRoute
        path="/aaqep-standards"
        exact={true}
        component={AAQEPStandard}
      />
      <ProtectedRoute
        path="/aaqep-standards/add"
        component={CreateOrEditAAQEPStandard}
      />
      <ProtectedRoute
        path="/aaqep-standards/edit/:aaqepId"
        component={CreateOrEditAAQEPStandard}
      />
      <ProtectedRoute
        path="/accreditation-dashboard"
        exact={true}
        component={AccreditationDashboard}
      />
      <ProtectedRoute path="/cohorts" exact={true} component={Cohort} />
      <ProtectedRoute path="/cohorts/add" component={CreateOrEditCohort} />
      <ProtectedRoute
        path="/cohorts/edit/:cohortId"
        component={CreateOrEditCohort}
      />
       <ProtectedRoute path="/cohorts-participants" exact={true} component={CohortParticipants} />
      <ProtectedRoute path="/cohorts-participants/add" component={CreateOrEditCohortParticipant} />
      <ProtectedRoute
        path="/cohorts-participants/edit/:participantId"
        component={CreateOrEditCohortParticipant}
      />
      <ProtectedRoute path='/educator-insights-dashboard' exact={true} component={EducatorInsightsDashboard} />
      //********** Public Routes **********
      <Route path="/" exact={true} component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/forgot" component={Forgot} />
      <Route path="/reset/:userId" component={Reset} />
      <Route path="/public/simulations" component={ListPublicSimulations} />
      <Route
        path="/public/simulation/detail/:simId"
        component={DetailPublicSimulation}
      />
      <Route
        path="/simulations/detail/:simLink/:userToken"
        component={DetailSimulation}
      />
      <Route path="/simulations/detail/:simId" component={DetailSimulation} />
      <Route
        path="/public/feedback/:linkId/:linkAuditId"
        component={SimFeedback}
      />
      <Route path="/visiting" component={Visiting} />
      <Route
        path="/simulations/view/:simId/:linkId/:linkAuditId"
        component={ViewSimulation}
      />
      <Route path="/payments/submit/:token" component={PaymentForm} />
      <Route path="/payments/successful" component={SuccessfulPayment} />
      <Route path="/payments/failed" component={FailedPayment} />
      <Route path="/failed" component={DocumentNotAvailable} />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={Login} isPrivate={false} />
    </Switch>
  );
}
