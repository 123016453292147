import {
  Card,
  CardContent,
  Box,
  Collapse,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Select from "react-select";
import TableV from "../../../components/Table";
import { useEffect, useState } from "react";
import {
  ExpandLess,
  ExpandMore,
  CheckCircle,
  Cancel,
} from "@material-ui/icons";
import EditProgress from "./EditProgress";
import appService from "../../../services/app.service";
import CohortService from "../../../services/CohortService";
import Loader from "../../../components/loader";

let selectedAssignment = {};

const ParticipantProgress = ({ data, loadDataAfterAssignment }) => {
  const [expanded, setExpanded] = useState({});
  const [openEditPopup, setOenEditPopup] = useState(false);
  const [educationRoles, setEducationRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const roles = await appService.getAll("educationRoles");

    setEducationRoles(
      roles?.data?.data.map((item) => ({
        value: item.id,
        label: item.role,
      }))
    );
  };

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the state for the specific collapse
    }));
  };

  const handleProgressStateChange = async (selectedOption, index) => {
    setLoading(false);
    const param = {
      id: index,
      progressStatus: selectedOption.value,
    };
    await CohortService().updateCohortParticipantProgressStatus(param);
    setLoading(false);
  };

  const handleEditPopup = (progressDetails) => {
    selectedAssignment = progressDetails;
    setOenEditPopup(!openEditPopup);
  };

  const [columnSchema] = useState([
    {
      dataField: "assignmentSimName",
      text: "Assignments",
      classes: "content-body",
      headerStyle: { minWidth: 250 },
      headerClasses: "table_header_color",
      sort: true,
      align: "left",
      formatter: (_, record) => {
        return (
          <p className="content-body breakWord" style={{ color: "#212529" }}>
            {record.assignment ? `${record.assignment}` : `${record.simName}`}
          </p>
        );
      },
      filterValue: (cell, row) => {
        // Combine assignment and simName for search functionality
        return `${row.assignment || ""} ${row.simName || ""}`;
      },
    },
    {
      dataField: "assignmentStatus",
      text: "Assignment Status",
      classes: "content-body text-center",
      headerClasses: "text-center",
      align: "center",
      headerStyle: { minWidth: 200 },
      formatter: (_, record) => (
        <>
          {record.assignmentStatus === true ? (
            <Tooltip title="Completed">
              <CheckCircle style={{ color: "green" }} titleAccess="Completed" />
            </Tooltip>
          ) : (
            <Tooltip title="In Complete">
              <Cancel style={{ color: "red" }} titleAccess="In Complete" />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      dataField: "progressNotes",
      text: "Progress Notes",
      classes: "paragraph-in-table content-body",
      headerClasses: "table_header_color",
      align: "left",
      sort: true,
      headerStyle: { minWidth: 350 },
    },
    {
      dataField: "action",
      text: "Action",
      classes: "action text-center",
      headerStyle: { minWidth: 150 },
      headerClasses: "text-center",
      align: "center",
      formatter: (_, record) => {
        return (
          <button
            className="btn btn-primary btn-sm buttons"
            onClick={() => handleEditPopup(record)}
          >
            <i className="fa fa-edit" title="Edit Standard" />
          </button>
        );
      },
    },
  ]);

  return (
    <>
      {data.length > 0 &&
        data.map((p) => (
          <Box sx={{ minWidth: 275 }} className="mt-3" key={p.participantId}>
            <Card variant="outlined" key={p.participantId}>
              <CardContent>
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center btn-styling">
                    <label className="sub-heading">
                      <b>Name: {p.name}</b>
                    </label>
                    <Tooltip
                      title={expanded[p.participantId] ? "Collapse" : "Expand"}
                    >
                      <IconButton
                        onClick={() => handleExpandClick(p.participantId)}
                        aria-expanded={expanded[p.participantId]}
                        aria-label="show more"
                      >
                        {expanded[p.participantId] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <Collapse in={expanded[p.participantId]}>
                  <div className="row">
                    <label className="col-3 form-control-label mt-2 label">
                      Progress Status
                    </label>
                    <div className="col-9">
                      <Select
                        name="reportTypes"
                        className="basic-multi-select fields-font"
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "175px", // Enables scrolling
                            overflowY: "auto",
                          }),
                        }}
                        isMulti={false}
                        options={educationRoles}
                        defaultValue={p.progressStatus}
                        onChange={(selectedOption) =>
                          handleProgressStateChange(
                            selectedOption,
                            p.participantId
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-3 overflow-auto">
                    <TableV
                      data={p.participantAssignmentStatusDTOList}
                      columns={columnSchema}
                      remote={false}
                      id="value"
                    />
                  </div>
                </Collapse>
              </CardContent>
            </Card>
          </Box>
        ))}
      {loading && <Loader loading={loading} />}
      {openEditPopup && (
        <EditProgress
          open={openEditPopup}
          handleClose={handleEditPopup}
          progressDetails={selectedAssignment}
          loadDataAfterAssignment={loadDataAfterAssignment}
        />
      )}
    </>
  );
};

export default ParticipantProgress;
