import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Transition } from "../../../components/Transition";
import { useState } from "react";
import appService from "../../../services/app.service";
import Loader from "../../../components/loader";

const EditProgress = ({
  open,
  handleClose,
  progressDetails,
  loadDataAfterAssignment,
}) => {
  const [editProgress, setEditProgress] = useState(progressDetails);
  const [loading, setLoading] = useState(false);

  const handleProgressNoteChange = (e) => {
    const progressNotes = e.target.value;
    setEditProgress((prevState) => ({
      ...prevState,
      progressNotes: progressNotes,
    }));
  };

  const handleAssignmentStatus = (e) => {
    const status = e.target.checked;
    setEditProgress((prevState) => ({
      ...prevState,
      assignmentStatus: status,
    }));
  };

  const handleUpdateEditProgress = async () => {
    setLoading(true);
    const params = {
      id: editProgress.progressId,
      participantId: editProgress.participantId,
      assignmentStatus: editProgress.assignmentStatus,
      progressNotes: editProgress.progressNotes,
    };
    await appService.submit(
      params,
      "cohortParticipant/updateParticipantProgress"
    );
    loadDataAfterAssignment();
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Edit Progress</DialogTitle>
      <DialogContent>
        <form className="form-horizontal" noValidate={true}>
          <div className="form-group row">
            <label className="col-sm-3 form-control-label content-body">
              Participant Name
            </label>
            <div className="col-sm-9">
              <input
                name="name"
                type="text"
                value={editProgress.participantName}
                className="form-control form-control-success fields-font"
                readOnly={true}
              />
            </div>
          </div>
          {editProgress.assignment != null && (
            <div className="form-group row">
              <label className="col-sm-3 form-control-label content-body">
                Assignment
              </label>
              <div className="col-sm-9">
                <textarea
                  name="assignment"
                  rows={5}
                  maxLength={1000}
                  placeholder="Non Sim Assignments"
                  className="form-control form-control-success fields-font"
                  value={editProgress.assignment.split("Assignment: ")[1]}
                  readOnly={true}
                />
              </div>
            </div>
          )}
          {editProgress.simName != null && (
            <div className="form-group row">
              <label className="col-sm-3 form-control-label content-body">
                Simulation
              </label>
              <div className="col-sm-9">
                <input
                  name="name"
                  type="text"
                  value={editProgress.simName.split("Sim:")[1]}
                  className="form-control form-control-success fields-font"
                  readOnly={true}
                />
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-sm-3 form-control-label label">
              Progress Notes
            </label>
            <div className="col-sm-9">
              <input
                name="label"
                type="text"
                maxLength={500}
                placeholder="Progress Notes"
                className="form-control form-control-success fields-font"
                value={editProgress.progressNotes}
                onChange={handleProgressNoteChange}
              />
            </div>
          </div>
          {editProgress.simName == null && (
            <div className="form-group row">
              <label className="col-sm-3 form-control-label label mt-2">
                Assignment Status
              </label>
              <div className="col-sm-9">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="text-success"
                      checked={editProgress.assignmentStatus}
                      onChange={handleAssignmentStatus}
                    />
                  }
                  label="Mark As Complete"
                />
              </div>
            </div>
          )}
          <div className="form-group row">
            <div className="col-sm-9 offset-sm-3">
              <button
                type="button"
                onClick={handleUpdateEditProgress}
                className="btn btn-submit float-right buttons"
              >
                Update Progress
              </button>
              <button
                type="button"
                className="btn btn-cancel float-right buttons"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        {loading && <Loader loading={loading} />}
      </DialogContent>
    </Dialog>
  );
};

export default EditProgress;
