import React from 'react';
import { Link } from 'react-router-dom';
import { BaseProp, Form } from '../../../types/index';
import auth from "../../../services/auth";
import SimpleReactValidator from 'simple-react-validator';
import logo from '../../../assets/img/new_ss_logo.png';
import Loader from '../../../components/loader';
import { connect } from 'react-redux';
import { altAttribute } from '../../../utils/utils';
import { HELP_PAGE_LINK } from "../../../constants";


class Login extends React.Component<BaseProp, Form> {

    state: Form = {
        email: '',
        password: '',
        msg: '',
        loading: false
    }
    validator;

    login(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            auth.login(this.state.email, this.state.password, (err) => {
                this.setState({ loading: false });
                if (err) {
                    this.setState({ msg: err });
                    return;
                } else {
                    this.setState({ msg: '  ' });
                    this.props.history.push("/dashboard");
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    }

    componentWillMount = () => {
        this.validator = new SimpleReactValidator({
            messages: {
                default: 'Field is required!'  // will override all messages
            },
        });
    }

    handleChange = (event: { target: { name: any; value: any; } }) => {
        const newState = { [event.target.name]: event.target.value } as Pick<Form, keyof Form>;
        this.setState(newState);
        this.setState({ msg: '' });
    }

    render() {
        return (
            <div className="page login-page">
                <div className="container d-flex align-items-center">
                    <div className="form-holder has-shadow">
                        <div className="row">
                            {/* <!-- Logo & Information Panel--> */}
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="info d-flex align-items-center">
                                    <div className="content">
                                        <div className="logo">
                                            <img src={logo} alt={altAttribute.logoAlt} className='responsive-image' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Form Panel    --> */}
                            <div className="col-lg-6 bg-white col-md-12 col-sm-12">
                                <div className="form d-flex align-items-center">
                                    <div className="content">
                                        <form method="post" className="form-validate">
                                            <div className="form-group">
                                                <label htmlFor="email" className="form-control-label required label">Email</label>
                                                <input id="email" type="email" name="email" className="input-material fields-font" value={this.state.email} onChange={(event) => this.handleChange(event)} />
                                                {this.validator.message('email', this.state.email, 'required')}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="login-password" className="form-control-label required label">Password</label>
                                                <input id="login-password" type="password" name="password" className="input-material fields-font" value={this.state.password} onChange={(event) => this.handleChange(event)} />
                                                {this.validator.message('password', this.state.password, 'required')}
                                            </div>
                                            <div className={this.state.msg === '' ? 'hidden' : 'form-group'}>
                                                {(this.state.msg !== '') && <span className="text-danger" style={{ fontSize: 15 }}>Issue getting access?<br />1. If you are a student of an ed leadership or aspiring teacher program you will not have an account. You will receive links from your professor. If your professor suggested you receive links directly from SchoolSims, please email <a href="mailto: cx@schoolsims.com">cx@schoolsims.com</a>  for access. <br /><br /> 2. If you cannot log in and you have already tried using the Forgot Password link:<br />a. Check your spam email folder for an email from <a href="#">ed@schoolsims.com</a> for a password reset link<br />b. If you do not see a reset email, send an email to <a href="mailto: cx@schoolsims.com">cx@schoolsims.com</a> for further assistance. <br /> <br /> 3. For frequently asked questions, please visit <a href={HELP_PAGE_LINK} target='_blank'>Frequently Asked Questions</a>. </span>}
                                            </div>
                                            <button id="login" type='button' className="btn btn-primary buttons" onClick={(event) => this.login(event)}>Login</button>
                                        </form>
                                        <Link to='/forgot'>
                                            <span className="forgot-pass">Forgot Password?</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(this.state.loading) && <div className="overlay">
                        <Loader loading={this.state.loading} />
                    </div>}
                </div>
            </div >

        );
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
