import { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import auth from "../../../services/auth";
import DasboardService from "../../../services/DashboardService";
import CustomBarChart from "./BarChart";
import { PIE_CHART_COLORS } from "../../../utils/utils";
import { Collapse, IconButton, Tooltip as TP } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ChartToolTip from "./ChartTooltip";

// Function to calculate percentage labels
const renderCustomizedLabel = ({ name, value, percent }) => {
  return `${(percent * 100).toFixed(2)}%`;
};

const CustomLegend = ({ payload }) => {
  return (
    <div className="position-relative pie_legend_div">
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className="legend_payload_div">
          <span
            style={{
              backgroundColor: entry.color,
            }}
            className="legend_payload_span"
          />
          <span className="legend_payload_span_text">{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const ClientAdminDashboard = () => {
  const [data, setData] = useState([]);
  const [isDataForChart, setIsDataForChart] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const authenticatedUserDetail = auth.getUserDetails();

  const loadData = async () => {
    const cohortProgress =
      await DasboardService().getCohortParticipantProgressStatusPercentageByAccount(
        authenticatedUserDetail?.accId
      );
    setIsDataForChart(cohortProgress?.data.every((item) => item.value === 0));
    setData(cohortProgress?.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {/* Full-width header */}
      <div className="card w-100">
        <div className="card-header d-flex justify-content-between align-items-center w-100 btn-styling">
          <div className="flex-grow-1">
            <label className="sub-heading">
              <b>{` ${
                authenticatedUserDetail.roleName === "Role_Super_Admin"
                  ? "K-12 "
                  : ""
              }Learning and Development Charts`}</b>
            </label>
          </div>
          <TP title={expanded ? "Collapse" : "Expand"}>
            <IconButton
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </TP>
        </div>
      </div>

      {/* Expandable Content */}
      <Collapse in={expanded} style={{ width: "100%" }}>
        <div className="d-flex flex-wrap w-100">
          {/* Pie Chart Section */}
          <div className="col-12 col-md-12 d-flex flex-column align-items-center p-3">
            <>
              {" "}
              <h3 className="sub-heading text-center">
                {` ${
                  authenticatedUserDetail.roleName === "Role_Super_Admin"
                    ? "K-12 "
                    : ""
                }Leadership Pipeline Progress`}
              </h3>
              {!isDataForChart ? (
                <>
                  <ResponsiveContainer width="100%" height={450}>
                    <PieChart margin={{ top: 20 }}>
                      <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={Math.min(130, window.innerWidth * 0.12)} // Responsive radius
                        fill="#8884d8"
                        label={renderCustomizedLabel}
                        legendType="square"
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={PIE_CHART_COLORS[index]}
                          />
                        ))}
                      </Pie>
                      <Tooltip
                        content={
                          <ChartToolTip prefix="Percentage:" suffix="%" />
                        }
                      />
                      <Legend content={<CustomLegend />} />
                    </PieChart>
                  </ResponsiveContainer>
                </>
              ) : (
                <div className="accreditation-msg">No Data Exists</div>
              )}
            </>
          </div>
        </div>

        <CustomBarChart />
      </Collapse>
    </>
  );
};

export default ClientAdminDashboard;
