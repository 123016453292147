import { useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../../../components/loader";
import Layout from "../../../layout/Layout";
import AppService from "../../../services/app.service";
import Select from "react-select";
import AlertDialogSlide from "../../../components/deleteModal";
import auth from "../../../services/auth";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  cohort: "",
  active: true,
  progressStatus: 1,
};

let prevCohort = {};

const CreateOrEditCohortParticipant = (props) => {
  const [formState, setFormState] = useState({ ...initialState });
  const [cohortList, setCohortList] = useState([]);
  const [participantData, setParticipantData] = useState();
  const [isActiveButtonDisable, setIsActiveButtonDisable] = useState(false);
  const [, forceUpdate] = useState(0);
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [loading, setLoading] = useState(false);
  const authenticatedUserDetail = auth.getUserDetails();
  const [isEdit, setIsEdit] = useState(
    !props["match"].params.participantId
      ? { title: "Add", submitButtonTitle: "Create" }
      : {
          participantId: props["match"].params.participantId,
          title: "Edit",
          submitButtonTitle: "Update",
        }
  );
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [uniqueEmail, setUniqueEmail] = useState(false);
  const [showCohortChangeModal, setShowCohortChangeModal] = useState(false);

  const loadData = async () => {
    setLoading(true);

    const cohortsList = await AppService.getAllWithData(
      "cohorts/getAllActiveCohorts",
      authenticatedUserDetail?.accId
    );

    const mappedCohorts = cohortsList?.data?.data.map((cohort) => ({
      value: cohort.id,
      label: cohort.name,
    }));

    setCohortList(mappedCohorts);

    if (isEdit.participantId) {
      const cohortParticipant = await AppService.get(
        isEdit.participantId,
        "cohortParticipant"
      );

      const { cohort, ...participantData } = cohortParticipant?.data;
      setFormState((prevState) => ({
        ...prevState,
        ...participantData,
        cohort: { value: cohort.value, label: cohort.label },
        active: cohortParticipant?.data?.active ?? prevState.active,
      }));
      prevCohort = { value: cohort.value, label: cohort.label };
      setParticipantData(cohortParticipant?.data);
      console.log("----", cohortParticipant?.data?.cohort)
      setIsActiveButtonDisable(!(cohortParticipant?.data?.cohort?.activeStatus && cohortParticipant?.data?.cohort?.status));
    }
    setLoading(false);
  };

  const handleCohortChange = (option) => {
    setFormState((prevState) => ({ ...prevState, cohort: option }));
    setIsActiveButtonDisable(false)
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChange = (event) => {
    const newState = { [event.target.name]: event.target.value };
    setFormState((prevState) => ({ ...prevState, ...newState }));
    if (event.target.name == "email") {
      setUniqueEmail(false);
    }
  };

  const handleCheckBox = (event) => {
    const newState = { [event.target.name]: event.target.checked };
    setFormState((prevState) => ({ ...prevState, ...newState }));
    setIsShowingModal(!event.target.checked ? true : false);
  };

  const checkCohortChange = (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      return simpleValidator.current.showMessages();
    }
    if (formState?.id && prevCohort?.value !== formState.cohort?.value) {
      setShowCohortChangeModal(true);
    } else {
      submitForm();
    }
  };

  const handleNoForCohortChange = () => {
    setFormState((prevState) => ({ ...prevState, cohort: prevCohort }));
    setShowCohortChangeModal(false);
  };

  const submitForm = async (e) => {
    setShowCohortChangeModal(false);
    setLoading(true);
    const cohortParticipant = await AppService.submit(
      formState,
      "cohortParticipant"
    );
    if (cohortParticipant?.data?.message == "Data Integrity issue") {
      setUniqueEmail(true);
      setLoading(false);
      return;
    }
    if (cohortParticipant.status === 201) {
      props.history.push("/cohorts-participants");
    } else {
      console.debug("Error while saving the participant");
    }
  };

  const handleCancel = (e) => {
    props.history.push("/cohorts-participants");
  };

  const handleAlertDialogNo = () => {
    setFormState((prevState) => ({
      ...prevState,
      active: true,
    }));
    setIsShowingModal(false);
  };

  const handleAlertDialogYes = () => {
    setIsShowingModal(false);
  };

  return (
    <div>
      <Layout
        title="Participant Management"
        history={props.history}
        titleUrl="/cohorts-participants"
        subTitle={`${isEdit.title} Participant`}
        activeParticipantLink={true}
        showBreadCrumb={true}
      >
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* <!-- Horizontal Form--> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="sub-heading">{isEdit.title} Participant</h3>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" noValidate={true}>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label required label">
                          First Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            name="firstName"
                            type="text"
                            maxLength={255}
                            placeholder="First Name"
                            className="form-control form-control-success fields-font"
                            value={formState.firstName}
                            onChange={(event) => handleChange(event)}
                          />
                          {simpleValidator.current.message(
                            "name",
                            formState.firstName,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label required label">
                          Last Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            name="lastName"
                            type="text"
                            maxLength={255}
                            placeholder="Last Name"
                            className="form-control form-control-success fields-font"
                            value={formState.lastName}
                            onChange={(event) => handleChange(event)}
                          />
                          {simpleValidator.current.message(
                            "name",
                            formState.lastName,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label required label">
                          Email
                        </label>
                        <div className="col-sm-9">
                          <input
                            name="email"
                            type="text"
                            maxLength={50}
                            placeholder="Email"
                            className="form-control form-control-success fields-font"
                            value={formState.email}
                            onChange={(event) => handleChange(event)}
                            readOnly={formState?.id}
                          />
                          {simpleValidator.current.message(
                            "email",
                            formState.email,
                            "required|email"
                          )}
                          {uniqueEmail && (
                            <div className="error">
                              The email must be unique
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label required label">
                          Select Cohort
                        </label>
                        <div className="col-sm-9">
                          <Select
                            name="cohort"
                            isMulti={false}
                            options={cohortList}
                            value={formState.cohort}
                            className="basic-multi-select fields-font"
                            onChange={handleCohortChange}
                          />
                          {simpleValidator.current.message(
                            "cohort",
                            formState.cohort,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="form-group row mt-3">
                        <label className="col-sm-12 form-control-label label formLabel">
                          <b className="textRed fontSize">Note:</b> We will set
                          the participant's progress status default to "In
                          cohort." You can update the participant's progress
                          status at any time in Educator Insights Dashboard.
                        </label>
                      </div>
                      {isEdit.participantId && (
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label"></label>
                          <div className="col-sm-9">
                            <div className="custom-control custom-checkbox checkbox-xl">
                              <input
                                id="active"
                                type="checkbox"
                                checked={formState.active}
                                name="active"
                                className="custom-control-input"
                                onChange={(event) => handleCheckBox(event)}
                                disabled={isActiveButtonDisable}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <div className="col-sm-9 offset-sm-3">
                          <button
                            type="submit"
                            onClick={checkCohortChange}
                            className="btn btn-submit float-right buttons"
                          >
                            {isEdit.submitButtonTitle}
                          </button>
                          <button
                            type="button"
                            className="btn btn-cancel float-right buttons"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      <AlertDialogSlide
        show={isShowingModal}
        handleNo={handleAlertDialogNo}
        handleYes={handleAlertDialogYes}
        title="Warning!"
        positiveButtonTitle="Yes"
        negativeButtonTitle="No"
      >
        <p>
          Are you sure you want to deactivate all assignments and simulation
          links associated with{" "}
          <span className="span">
            {participantData?.firstName + " " + participantData?.lastName}
          </span>{" "}
          ?
        </p>
      </AlertDialogSlide>
      <AlertDialogSlide
        show={showCohortChangeModal}
        handleNo={handleNoForCohortChange}
        handleYes={submitForm}
        title="Warning!"
        positiveButtonTitle="Okay"
        negativeButtonTitle="Cancel"
      >
        <p>
          By changing the participant cohort <span className="span">
            {participantData?.cohort?.label}
          </span>{" "}, all previously generated links
          associated with the participant{" "}
          <span className="span">
            {participantData?.firstName + " " + participantData?.lastName}
          </span>{" "}
          will be permanently deleted. This action cannot be undone.
        </p>
      </AlertDialogSlide>
      {loading && (
        <div className="overlay">
          <Loader loading={loading} />
        </div>
      )}
    </div>
  );
};

export default CreateOrEditCohortParticipant;
