import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DefaultHeader from "../../container/default-layout/header";
import DefaultSideBar from "../../container/default-layout/side.bar";
import { DefaultFooter } from "../../container/default-layout/footer";
import { UserProps, TableState } from "../../../types/index";
import AppService from "../../../services/app.service";
import auth from "../../../services/auth";
import Loader from "../../../components/loader";
import AlertDialogSlide from "../../../components/deleteModal";
import Table from "../../../components/Table";
import { ACCOUNT_TYPE } from "../../../utils/constants";

let simLink = "";
let emails = "";
let defaultSortedColumn = "id";
let defaultSortedOrder = "desc";
class User extends React.Component<UserProps, TableState> {
  readonly state: TableState = {
    columns: [
      {
        dataField: "id",
        text: "ID",
        sort: true,
        classes: "content-body",
        headerStyle: { minWidth: 40 },
        headerClasses: "table_header_color",
      },
      {
        dataField: "createdBy",
        text: "Sent By",
        sort: true,
        headerClasses: "table_header_color",
      },
      {
        dataField: "email",
        text: "Received By",
        sort: true,
        classes: "content-body",
        headerClasses: "table_header_color",
        headerStyle: { minWidth: 50 },
      },
      {
        dataField: "accName",
        text: "Account",
        classes: "content-body",
        headerStyle: { minWidth: 90 },
      },
      {
        dataField: "simName",
        text: "Simulation",
        sort: true,
        classes: "content-body",
        headerStyle: { minWidth: 90 },
        headerClasses: "table_header_color",
      },
      {
        dataField: "activeStatusText",
        text: "Status",
        sort: true,
        classes: "content-body",
        headerClasses: "text-center table_header_color",
        align: "center",
      },
      {
        dataField: "link",
        text: "Link",
        classes: "content-body",
        headerClasses: "text-center",
        align: "center",
        headerStyle: { minWidth: 125 },
        formatter: (cell, record) => {
          return (
            <Fragment>
              <Link
                target="_blank"
                to={"/simulations/detail/" + record.link + "/" + record.token}
              >
                Simulation Link
              </Link>
            </Fragment>
          );
        },
      },
      {
        dataField: "action",
        text: "Action",
        classes: "content-body",
        headerClasses: "text-center",
        headerStyle: { width: 60 },
        align: "center",
        formatter: (cell, record) => {
          return (
            <Fragment>
              <button
                id={record.id}
                className="btn btn-danger btn-sm"
                onClick={() =>
                  this.deleteRecord(record.id, record.simName, record.email)
                }
              >
                <i className="fa fa-trash" title="Delete Link" />
              </button>
            </Fragment>
          );
        },
      },
    ],
    data: [],
    id: 0,
    isShowingModal: false,
    isShowingRowsModal: false,
    isShowingNotDeleteModal: false,
    accId: 0,
    loading: false,
    totalRecords: 0,
    selectedRowsCount: 0,
    selectedRows: [],
    page: 0,
    perPage: 10,
    currentColumn: { name: "id", order: "desc" },
  };

  handleClick = () => this.setState({ isShowingModal: true });
  handleClose = () => this.setState({ isShowingModal: false });

  deleteRecord(record, simName, email) {
    let row = this.state.selectedRows.filter((row) => row.id === record);
    if (row.length === 0) {
      this.setState({ id: record });
      simLink = simName;
      emails = email;
      this.handleClick();
    } else {
      this.setState({ isShowingNotDeleteModal: true });
    }
  }

  handleProcess = async () => {
    this.handleClose();
    this.setState({ loading: true });
    const links = await AppService.submit(
      { id: this.state.id, accId: this.state.accId },
      "sims/deactivateLink"
    );
    this.setState({ loading: false });
    if (links.status === 200) {
      this.loadData(
        `sims/links?filter_value=&page_number=${
          this.state.page && this.state.page !== 0 ? this.state.page : 1
        }&page_size=${
          this.state.perPage
        }&sort_column=${defaultSortedColumn}&sort_order=${defaultSortedOrder}`
      );
    }
  };

  loadData = async (queryString: string) => {
    var accountId =
      auth.getUserDetails().accId != null ? auth.getUserDetails().accId : 0;
    let urlParts = queryString.split("?");
    let url = urlParts[0] + `/${accountId}?${urlParts[1]}`;
    let urlParams = urlParts[1].split("&");
    let currentPage = urlParams[1].split("=")[1];
    let currentPageSize = urlParams[2].split("=")[1];
    defaultSortedColumn = urlParams[3].split("=")[1];
    defaultSortedOrder = urlParams[4].split("=")[1];
    this.setState({ loading: true, accId: accountId });
    const users = await AppService.getAll(url);
    this.setState({
      data: users.data.links,
      loading: false,
      totalRecords: users.data.totalRecords,
      page: +currentPage,
      perPage: +currentPageSize,
    });
  };

  handleOnRowSelect = (
    rows,
    currentPage,
    currentPerPage,
    currentSort,
    currentOrder
  ) => {
    this.setState({
      page: currentPage,
      perPage: currentPerPage,
      selectedRowsCount: rows.length,
      selectedRows: rows,
      currentColumn: { name: currentSort, order: currentOrder },
    });
  };

  syncTableState = (currentPage, currentPerPage, currentSort, currentOrder) => {
    this.setState({
      page: currentPage,
      perPage: currentPerPage,
      currentColumn: { name: currentSort, order: currentOrder },
    });
  };

  handleRowsModel = () => {
    this.setState({ isShowingRowsModal: true });
  };

  handleRowsModelClose = () => {
    this.setState({ isShowingRowsModal: false });
  };

  handleNotDeleteModelClose = () => {
    this.setState({ isShowingNotDeleteModal: false });
  };

  resetRowsCount = () => {
    this.setState({ selectedRows: [], selectedRowsCount: 0 });
  };

  handleBulkLinkDelete = async () => {
    this.handleRowsModelClose();
    this.setState({ loading: true });
    const ids = this.state.selectedRows.map((row) => row.id);
    const links = await AppService.submit(
      { linkIds: ids, accId: this.state.accId },
      "sims/deactivateBulkLink"
    );
    this.setState({ loading: false });
    if (links.status === 200) {
      this.loadData(
        `sims/links?filter_value=&page_number=${this.state.page}&page_size=${this.state.perPage}&sort_column=${this.state.currentColumn.name}&sort_order=${this.state.currentColumn.order}`
      );
    }
    this.resetRowsCount();
  };

  componentDidMount = () => {
    this.loadData(
      `sims/links?filter_value=&page_number=1&page_size=10&sort_column=${defaultSortedColumn}&sort_order=${defaultSortedOrder}`
    );
    const accType = auth.getUserDetails().accType;
    this.setState((prevState) => {
      let columns = prevState.columns;
      if (
        accType != null &&
        accType != "" &&
        accType != ACCOUNT_TYPE.HIGHER_ED
      ) {
        columns.splice(5, 0, {
          dataField: "cohortName",
          text: "Cohort",
          classes: "content-body",
          headerStyle: { minWidth: 80 },
        });
      }
      return {
        columns: [...columns],
      };
    });
  };

  render() {
    return (
      <div>
        <div className="page">
          <DefaultHeader history={this.props.history} />
          <div className="page-content d-flex align-items-stretch fields-font">
            <DefaultSideBar activeLMLink={true} />
            <div className="content-inner">
              {/* <!-- Page Header--> */}
              <header className="page-header">
                <div className="container-fluid">
                  <h2 className="heading">Link Management</h2>
                </div>
              </header>
              {/* <!-- Breadcrumb--> */}
              <div className="breadcrumb-holder container-fluid">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item breadcrumb-list">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active breadcrumb-list">
                    Link Management
                  </li>
                </ul>
              </div>
              <section className="tables">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-close">
                          <div className="dropdown">
                            <Link
                              className="dropdown-toggle"
                              to={`/simulations/link/0/${this.state.accId}`}
                            >
                              <i
                                className="fas fa-plus"
                                title="Create sim Link"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="card-header d-flex align-items-center">
                          <h3 className="sub-heading">Simulation Links</h3>
                        </div>

                        <div className="card-body">
                          {this.state.selectedRowsCount !== 0 && (
                            <div className="row mb-2">
                              <div className="col-6 pt-3 pb-2 alert-info">
                                <h6>
                                  Total Selected Rows{" "}
                                  {this.state.selectedRowsCount}
                                </h6>
                              </div>
                              <div className="col-6 pt-2 pb-2 text-right alert-info">
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    this.setState({ isShowingRowsModal: true })
                                  }
                                >
                                  <i
                                    className="fa fa-trash"
                                    title="Delete Link"
                                  />
                                </button>
                              </div>
                            </div>
                          )}
                          <div className="table-responsive data-table-wrapper table-heading">
                            <Table
                              data={this.state.data}
                              columns={this.state.columns}
                              total={this.state.totalRecords}
                              apiUrl="sims/links"
                              getData={this.loadData}
                              defaultSort={defaultSortedColumn}
                              defaultOrder={defaultSortedOrder}
                              prvSelectedRows={this.state.selectedRows}
                              handleOnRowSelect={this.handleOnRowSelect}
                              isMultiSelect={true}
                              id="id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <DefaultFooter />
              {this.state.loading && (
                <div className="overlay">
                  <Loader loading={this.state.loading} />
                </div>
              )}
            </div>
          </div>
        </div>
        <AlertDialogSlide
          show={this.state.isShowingModal}
          handleNo={this.handleClose}
          handleYes={this.handleProcess}
          title="Delete Simulation Link"
        >
          <p className="fields-font">
            Are you sure you want to delete the simulation link for the{" "}
            <span className="span">{simLink}</span> against the email{" "}
            <span className="span">{emails}</span> ?
          </p>
        </AlertDialogSlide>

        <AlertDialogSlide
          show={this.state.isShowingRowsModal}
          handleNo={this.handleRowsModelClose}
          handleYes={this.handleBulkLinkDelete}
          title="Delete Simulation Links"
        >
          <p className="fields-font">
            Are you sure you want to delete the simulation link for the selected
            rows?
          </p>
        </AlertDialogSlide>

        <AlertDialogSlide
          show={this.state.isShowingNotDeleteModal}
          handleNo={this.handleNotDeleteModelClose}
          title="Warning!"
        >
          <p className="fields-font">
            If you want to delete the selected row by the "Action" button then
            first unselect the row.
          </p>
        </AlertDialogSlide>
      </div>
    );
  }
}

export default User;
