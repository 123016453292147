import { PIE_CHART_COLORS } from "../../../utils/utils";

const ChartToolTip = ({ active, payload, prefix, suffix }) => {
  if (active && payload?.length) {
    const data = payload[0].payload; // Extract full data object
    return (
      <div className="charts_tooltip_style">
        {/* Display name and value */}
        <div className="chart_tooltip_font">
          {data.name}
          <br />
          <span style={{ color: PIE_CHART_COLORS[0] }}>
            {prefix} {data.value}{suffix}
          </span>
        </div>
      </div>
    );
  }
  return null;
};

export default ChartToolTip