import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import Select from "react-select";
import Layout from "../../../layout/Layout";
import ParticipantProgress from "./ParticipantProgress";
import { useEffect, useState } from "react";
import AssignSimulations from "./AssignSimulations";
import AddAssignment from "./AddAssignment";
import auth from "../../../services/auth";
import SendSimulationLink from "./SendSimulationLink";
import Loader from "../../../components/loader";
import CohortService from "../../../services/CohortService";
import { base64ToExcel } from "../../../utils/utils";

const EducatorInsightsDashboard = () => {
  const [openAssignSimModal, setOpenAssignSimModal] = useState(false);
  const [openAddAssignmentModal, setOpenAddAssignmentModal] = useState(false);
  const [openSendSimLinkModal, setOpenSendSimLinkModal] = useState(false);
  const [selectedCohort, setSelectedCohort] = useState(null);
  const [cohortSelection, setCohortSelection] = useState(null);
  const [reportParam, setReportParam] = useState("");
  const [cohorts, setCohorts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cohortParticipantData, setCohortParticipantData] = useState(null);
  const [status, setStatus] = useState({
    active: true,
    inactive: false,
  });
  const [error, setError] = useState(false);
  const authenticatedUserDetail = auth.getUserDetails();

  const loadData = async () => {
    setLoading(true);
    const activeCohorts = await CohortService().getActiveCohorts(
      authenticatedUserDetail?.accId
    );
    setCohorts(
      activeCohorts?.data?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleChange = (event) => {
    setStatus({
      ...status,
      [event.target.name]: event.target.checked,
    });
    setError(false); // Reset error when a checkbox is selected
  };

  const handleSelectedCohort = (selected) => {
    setCohortSelection(selected);
  };

  const handleAssignSimModal = () => {
    setOpenAssignSimModal(!openAssignSimModal);
  };

  const handleAddAssignmentModal = () => {
    setOpenAddAssignmentModal(!openAddAssignmentModal);
  };

  const handleSendSimLinkModal = () => {
    setOpenSendSimLinkModal(!openSendSimLinkModal);
  };

  const getActiveValue = () => {
    if (status.active && status.inactive) {
      return [true, false];
    } else if (status.inactive) {
      return [false];
    } else if (status.active) {
      return [true];
    }
    return null;
  };

  const loadDataByCohortId = async () => {
    setSelectedCohort(cohortSelection);
    setLoading(true);
    const cohortsList =
      await CohortService().getCohortParticipantInSightsDashboardData({
        cohortId: cohortSelection?.value,
        active: getActiveValue(),
      });
    setCohortParticipantData(cohortsList?.data?.data);
    setReportParam({
      cohortId: cohortSelection?.value,
      participantActiveStatus: getActiveValue(),
      account: auth.getUserDetails().accId,
    });
    setLoading(false);
  };

  const handleGenerateReport = async () => {
    setLoading(true);
    const base64Report = await CohortService().generateCohortReport(
      reportParam
    );
    base64ToExcel(
      base64Report?.data?.data?.excelFileBase64,
      "Cohort Progress Report"
    );
    setLoading(false);
  };

  const isDisableGetCohortDataButton = () => {
    const isDisable =
      cohortSelection == null || getActiveValue() == null ? true : false;
    return isDisable;
  };

  return (
    <div>
      <Layout
        title="Educator Insights Dashboard"
        subTitle={` Educator Insights Dashboard`}
        activeEducatorInsightsDashboard={true}
        showBreadCrumb={false}
      >
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header h-25">
                    <h3 className="sub-heading">Educator Insights Dashboard</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="col-3 form-control-label mt-2 required label">
                        Select Cohort
                      </label>
                      <div className="col-9">
                        <Select
                          name="cohorts"
                          className="basic-multi-select fields-font"
                          isMulti={false}
                          options={cohorts}
                          onChange={handleSelectedCohort}
                        />
                      </div>
                      <label className="col-3 form-control-label mt-2 content-body required label mt-3">
                        Participants With Status
                      </label>
                      <div className="col-9 mt-2 z-index-unset">
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={status.active}
                                onChange={handleChange}
                                name="active"
                              />
                            }
                            label="Active"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={status.inactive}
                                onChange={handleChange}
                                name="inactive"
                              />
                            }
                            label="InActive"
                          />
                        </FormGroup>
                        {error && (
                          <FormHelperText style={{ color: "red" }}>
                            Please select at least one option.
                          </FormHelperText>
                        )}
                      </div>
                      <div className="col d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-submit buttons mt-2 me-2"
                          onClick={loadDataByCohortId}
                          disabled={isDisableGetCohortDataButton()}
                        >
                          Get Cohort Data
                        </button>
                      </div>
                    </div>
                    {cohortParticipantData && (
                      <>
                        <div className="form-group row mt-3">
                          <label className="col-sm-12 form-control-label label formLabel">
                            <span className="textRed fontSize font-weight-bold">
                              Note:
                            </span>{" "}
                            Assigning a simulation to participants involves two
                            steps, as mentioned below:
                            <ol className="ml-4">
                              <li>
                                Assign the simulation to the cohort by clicking
                                the{" "}
                                <span className="font-weight-bold">
                                  Assign Simulation
                                </span>{" "}
                                button.
                              </li>
                              <li>
                                Share the simulation link with participants by
                                clicking the{" "}
                                <span className="font-weight-bold">
                                  Send Simulation Link
                                </span>{" "}
                                button.
                              </li>
                            </ol>
                          </label>
                        </div>
                        <div className="row">
                          <div className="col d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-submit buttons mt-2 me-2"
                              onClick={handleAssignSimModal}
                            >
                              Assign Simulations
                            </button>
                            {cohortParticipantData?.length > 0 && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-submit buttons mt-2"
                                  onClick={handleSendSimLinkModal}
                                >
                                  Send Simulation Link
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-submit buttons mt-2"
                                  onClick={handleAddAssignmentModal}
                                >
                                  Add Assignment
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-submit buttons mt-2"
                                  onClick={handleGenerateReport}
                                >
                                  Generate Report
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        {cohortParticipantData?.length > 0 ? (
                          <ParticipantProgress
                            data={cohortParticipantData}
                            loadDataAfterAssignment={loadDataByCohortId}
                          />
                        ) : (
                          <div className="accreditation-msg">
                            No Data Exists
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {openAssignSimModal && (
          <AssignSimulations
            open={openAssignSimModal}
            handleClose={handleAssignSimModal}
            selected={selectedCohort}
          />
        )}
        {openAddAssignmentModal && (
          <AddAssignment
            open={openAddAssignmentModal}
            handleClose={handleAddAssignmentModal}
            selected={selectedCohort}
            loadDataByCohortIdByPagination={loadDataByCohortId}
          />
        )}
        {openSendSimLinkModal && (
          <SendSimulationLink
            open={openSendSimLinkModal}
            handleClose={handleSendSimLinkModal}
            selected={selectedCohort}
            loadDataAfterAssignment={loadDataByCohortId}
          />
        )}
        {loading && <Loader loading={loading} />}
      </Layout>
    </div>
  );
};

export default EducatorInsightsDashboard;
